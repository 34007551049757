import React from "react";

export default function Cloudy() {
  return (
    <div className="showing">
      <div className="scene">
        <div className="states">
          <div className="state cloudy">
            <div className="cloud">
              <div className="circles">
                <div className="circle-1"></div>
                <div className="circle-2"></div>
                <div className="circle-3"></div>
                <div className="circle-4"></div>
              </div>
              <div className="rect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
