import React from "react";
import { TbTemperatureFahrenheit, TbTemperatureCelsius } from "react-icons/tb";

export default function CtoF({ fahrenheit, setFahrenheit }) {
  function CtoFToggle() {
    setFahrenheit(!fahrenheit);
  }
  let currentTemp;

  if (fahrenheit) currentTemp = <TbTemperatureCelsius />;
  else currentTemp = <TbTemperatureFahrenheit />;
  return (
    <div>
      <div className="absolute flex flex-row justify-center items-center bg-white w-12 h-12 top-0 left-0 m-4 z-50 rounded-full opacity-90" onClick={CtoFToggle}>
        {currentTemp}
      </div>
    </div>
  );
}
