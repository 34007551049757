import React from "react";

export default function Sunny() {
  return (
    <div className="showing">
      <div className="scene">
        <div className="states">
          <div className="state sunny">
            <div className="sun"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
