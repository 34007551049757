import React from "react";

export default function Left({ areas, setAreas }) {
  function handleClick() {
    let temp = areas[areas.length - 1];
    let tempAreas = new Array(areas.length);
    let CurrentArray = areas;
    let index = 0;
    areas.forEach((element) => {
      if (areas.length === 1) {
        tempAreas = CurrentArray;
      }
      if (index === 0) {
        tempAreas[index + 1] = CurrentArray[index];
      } else if (index === areas.length - 1) {
        tempAreas[0] = temp;
      } else {
        tempAreas[index + 1] = CurrentArray[index];
      }
      index = index + 1;
    });
    setAreas(tempAreas);
    // localStorage.setItem("LocationList", JSON.stringify(tempAreas));
  }

  return (
    <div id="LeftArrow" className="fixed w-4 top-0 left-0 h-screen flex flex-row justify-center items-center p-4  z-20 opacity-100 transition duration-300 ease-in-out" onClick={handleClick}>
      <span className="text-white">&#10094;</span>
    </div>
  );
}
