import React, { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { TbPlus, TbSquareX } from "react-icons/tb";

export default function Add({ setAreas }) {
  const [locationName, setLocationName] = useState({ "city": "Fill Zip Code Above" });
  const [valid, setValid] = useState(false);
  // const [dropData, setDropData] = useState([]);
  // let HoldData = [];

  function AddLocation() {
    document.getElementById("AddLocation").classList.replace("-top-36", "top-0");
    let CardDivs = document.getElementsByClassName("Card");
    for (let i = 0; i < CardDivs.length; i++) {
      CardDivs[i].classList.replace("opacity-100", "opacity-30");
      CardDivs[i].classList.replace("top-0", "top-36");
    }
    document.getElementById("LeftArrow").classList.replace("opacity-100", "opacity-50");
    document.getElementById("RightArrow").classList.replace("opacity-100", "opacity-50");
  }

  function CloseLocationBox() {
    document.getElementById("AddLocation").classList.replace("top-0", "-top-36");
    let CardDivs = document.getElementsByClassName("Card");
    for (let i = 0; i < CardDivs.length; i++) {
      CardDivs[i].classList.replace("opacity-30", "opacity-100");
      CardDivs[i].classList.replace("top-36", "top-0");
    }
    document.getElementById("LeftArrow").classList.replace("opacity-50", "opacity-100");
    document.getElementById("RightArrow").classList.replace("opacity-50", "opacity-100");
  }

  // function LocationN(Input) {
  //   if (Input === "") {
  //     setDropData(HoldData);
  //   }
  //   // setDropData(HoldData);
  //   // setHoldData([]);
  //   axios.get("https://raw.githubusercontent.com/millbj92/US-Zip-Codes-JSON/master/USCities.json").then((response) => {
  //     for (let i = 0; i < response.data.length; i++) {
  //       // console.log(response.data[i].zip_code);
  //       if (response.data[i].city.toLowerCase().includes(Input.toLowerCase())) {
  //         if (HoldData.length < 10) {
  //           HoldData[HoldData.length] = response.data[i];
  //         }
  //         return;
  //       } else if (response.data[i].zip_code.toString().includes(Input)) {
  //         if (HoldData.length < 10) {
  //           HoldData[HoldData.length] = response.data[i];
  //         }
  //         // setHoldData([response.data[i].zip_code]);
  //       }
  //     }
  //     setDropData(HoldData);
  //     console.log(dropData);
  //     HoldData = [];
  //   });
  // }

  function LocationName(Zip) {
    if (Zip.length !== 5) {
      setLocationName({ "city": "Fill Zip Code Above" });
      setValid(false);
      return;
    }

    axios
      .get("https://raw.githubusercontent.com/millbj92/US-Zip-Codes-JSON/master/USCities.json")
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].zip_code === Number(Zip)) {
            setLocationName(response.data[i]);
            i = response.data.length + 1;
            return setValid(true);
          } else {
            setValid(false);
          }
        }
      })
      .catch(function () {
        setLocationName({ "city": "Fill Zip Code Above" });
      });
  }

  function addLocation() {
    if (valid === false) return;
    let data = JSON.parse(localStorage.getItem("LocationList")) || [];
    console.log(data);

    let index;
    if (data.length > 0) {
      index = data[data.length - 1].Index + 1;
    } else index = 0;

    const currentLoaded = locationName;
    let newData = {
      "ID": uuidv4(),
      "Index": index,
      "Name": currentLoaded.city,
      "Lat": currentLoaded.latitude,
      "Lon": currentLoaded.longitude,
      "Zip": currentLoaded.zip_code,
      "State": currentLoaded.state,
    };
    data.push(newData);
    localStorage.setItem("LocationList", JSON.stringify(data));
    setAreas(data);
    document.getElementById("ZipInput").value = "";
    setLocationName({ "city": "Fill Zip Code Above" });
    CloseLocationBox();
  }

  return (
    <>
      <div id="AddLocation" className="fixed w-full h-36 bg-transparent -top-36 left-0 rounded-b-lg transition-all z-50">
        <div className="flex flex-col justify-end items-end w-full h-auto p-4">
          <div className="flex flex-col justify-center items-center w-full h-auto AddCardBack CardContent rounded-2xl">
            <div>Location Zip Code (US Only)</div>
            <form>
              <div>
                <div className="flex flex-col w-48">
                  <input
                    id="ZipInput"
                    className="w-48 text-black"
                    name=""
                    type="text"
                    // autoComplete="off"
                    onChange={(e) => {
                      // LocationName(e.target.value);
                      LocationName(e.target.value);
                    }}
                  />
                  {/* {dropData.map((item, index) => {
                    return (
                      <div className="w-full bg-white text-black" key={index}>
                        {item.zip_code}
                      </div>
                    );
                  })} */}
                </div>
              </div>
              <div className="flex flex-row gap-12">
                <div className="w-full items-start">{locationName.city}</div>
                <button onClick={addLocation}>submit</button>
              </div>
              <input type="submit" className="hidden" />
            </form>
            {/* <button onClick={() => LocationN(ZipInput)}>response</button> */}
          </div>
          <div className="w-8 h-8 rounded-full text-white" onClick={CloseLocationBox}>
            <TbSquareX className="w-full h-full" />
          </div>
        </div>
      </div>
      <div className="fixed flex flex-row justify-center items-center bg-white w-12 h-12 top-0 right-0 m-4 z-40 rounded-full" onClick={AddLocation}>
        <TbPlus />
      </div>
    </>
  );
}
