import React from "react";
import * as Helper from "../Helper/HelperFunctions";

export default function GridData({ forecast, loaded, fahrenheit }) {
  let gridItems = [];

  let index = 0;
  if (loaded) {
    for (var key in forecast.data.properties) {
      if (typeof forecast.data.properties[key] === "object" && "uom" in forecast.data.properties[key]) {
        if (forecast.data.properties[key].values.length > 0) {
          let add = true;
          let value = forecast.data.properties[key].values[0].value;
          let uom = forecast.data.properties[key].uom;
          switch (uom) {
            case "wmoUnit:degC":
              value = Helper.cToF(value, fahrenheit);
              break;
            case "wmoUnit:percent":
              value = value + "%";
              break;

            case "wmoUnit:km_h-1":
              value = Helper.kphToMph(value);
              break;

            case "wmoUnit:mm":
              value = Helper.mmToInches(value);
              break;

            case "wmoUnit:m":
              value = Helper.mToFt(value);
              break;
            default:
              add = false;
              break;
          }
          if (add) {
            gridItems.push(
              <div className="GridItem flex flex-col" key={index}>
                <div className="sticky top-0 w-full CardBackTitle bg-opacity-90 flex flex-row justify-start">
                  <span className="opacity-100 text-xs">{Helper.cleanText(key)}</span>
                </div>
                <span className=" CardContent">{value}</span>
              </div>
            );
            index = index + 1;
          }
        }
      }
    }
  }

  //   if (loaded) {
  //     forecast.data.properties.map((items, index) => {
  //       console.log(items);
  //       if (items.values.length > 0) {
  //         gridItems[index] = (
  //           <div className="GridItem flex flex-col">
  //             <span>{Object.keys(forecast.data.properties)[index]}</span>
  //           </div>
  //         );
  //       }
  //     });
  //   }
  return <div className="Grid w-full h-full">{gridItems}</div>;
}
