import React from "react";
import * as Helper from "../Helper/HelperFunctions";
import Hourly from "./Hourly";

export default function HourlySection({ forecast, loaded, basicForecast, fahrenheit }) {
  let ArrayLength = 12;
  let DailyArray = new Array(ArrayLength);

  let MinMax = {
    "Min": 200,
    "Max": -200,
  };
  let Temps = new Array(ArrayLength);
  let Hours = new Array(ArrayLength);
  let dayMin = 200;
  let dayMax = -200;
  if (loaded) {
    let TempIndex = Helper.advanceIndex(forecast.data.properties.temperature.values, loaded);
    for (let i = 0; i < ArrayLength; i++) {
      let check = forecast.data.properties.temperature.values[TempIndex + i].value;
      Temps[i] = Helper.CtoF(forecast.data.properties.temperature.values[TempIndex + i].value, fahrenheit);
      Hours[i] = Helper.GetHourFromDate(forecast.data.properties.temperature.values[TempIndex + i].validTime, loaded);
      if (dayMin > check) dayMin = check;
      if (dayMax < check) dayMax = check;
    }
    MinMax = {
      "Min": Helper.CtoF(Math.ceil(dayMin), fahrenheit),
      "Max": Helper.CtoF(Math.ceil(dayMax), fahrenheit),
    };

    DailyArray = Temps.slice().sort(function (a, b) {
      return a - b;
    });
  }

  let date = new Date();
  date.setDate(date.getDate() - 1);

  return (
    <div className="w-80 flex flex-row gap-3 CardContent overflow-x-scroll">
      <div className="w-full sticky left-0 h-full flex flex-col justify-between py-6 HourlyHighLow">
        <span>{MinMax.Max}°</span>
        <span>{MinMax.Min}°</span>
      </div>
      {DailyArray.map((items, index) => {
        return <Hourly date={Hours[index]} MinMax={MinMax} forecast={forecast} loaded={loaded} DailyArray={DailyArray} Temp={Temps[index]} fahrenheit={fahrenheit} ArrayLength={ArrayLength} key={index} />;
      })}
      <div className="w-8"></div>
    </div>
  );
}
