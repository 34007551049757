let data = {
  "data": {
    "@context": [
      "https://geojson.org/geojson-ld/geojson-context.jsonld",
      {
        "@version": "1.1",
        "wx": "https://api.weather.gov/ontology#",
        "geo": "http://www.opengis.net/ont/geosparql#",
        "unit": "http://codes.wmo.int/common/unit/",
        "@vocab": "https://api.weather.gov/ontology#",
      },
    ],
    "type": "Feature",
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [-96.6836577, 43.5908735],
          [-96.6832878, 43.569294400000004],
          [-96.6534984, 43.569560100000004],
          [-96.6538618, 43.5911392],
          [-96.6836577, 43.5908735],
        ],
      ],
    },
    "properties": {
      "updated": "2023-01-20T16:44:55+00:00",
      "units": "us",
      "forecastGenerator": "BaselineForecastGenerator",
      "generatedAt": "2023-01-20T17:53:17+00:00",
      "updateTime": "2023-01-20T16:44:55+00:00",
      "validTimes": "2023-01-20T10:00:00+00:00/P7DT15H",
      "elevation": {
        "unitCode": "wmoUnit:m",
        "value": 398.9832,
      },
      "periods": [
        {
          "number": 1,
          "name": "Today",
          "startTime": "2023-01-20T11:00:00-06:00",
          "endTime": "2023-01-20T18:00:00-06:00",
          "isDaytime": true,
          "temperature": 26,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "5 mph",
          "windDirection": "SW",
          "icon": "https://api.weather.gov/icons/land/day/fog,30?size=medium",
          "shortForecast": "Areas Of Freezing Drizzle",
          "detailedForecast": "Areas of freezing drizzle and patchy fog. Cloudy, with a high near 26. Southwest wind around 5 mph. Chance of precipitation is 30%.",
        },
        {
          "number": 2,
          "name": "Tonight",
          "startTime": "2023-01-20T18:00:00-06:00",
          "endTime": "2023-01-21T06:00:00-06:00",
          "isDaytime": false,
          "temperature": 18,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "5 mph",
          "windDirection": "W",
          "icon": "https://api.weather.gov/icons/land/night/fog,30/ovc?size=medium",
          "shortForecast": "Areas Of Freezing Drizzle then Cloudy",
          "detailedForecast": "Areas of freezing drizzle and patchy fog before 9pm. Cloudy, with a low around 18. West wind around 5 mph. Chance of precipitation is 30%.",
        },
        {
          "number": 3,
          "name": "Saturday",
          "startTime": "2023-01-21T06:00:00-06:00",
          "endTime": "2023-01-21T18:00:00-06:00",
          "isDaytime": true,
          "temperature": 29,
          "temperatureUnit": "F",
          "temperatureTrend": "falling",
          "windSpeed": "5 to 10 mph",
          "windDirection": "WSW",
          "icon": "https://api.weather.gov/icons/land/day/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy. High near 29, with temperatures falling to around 25 in the afternoon. West southwest wind 5 to 10 mph.",
        },
        {
          "number": 4,
          "name": "Saturday Night",
          "startTime": "2023-01-21T18:00:00-06:00",
          "endTime": "2023-01-22T06:00:00-06:00",
          "isDaytime": false,
          "temperature": 14,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "5 mph",
          "windDirection": "WNW",
          "icon": "https://api.weather.gov/icons/land/night/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a low around 14. West northwest wind around 5 mph.",
        },
        {
          "number": 5,
          "name": "Sunday",
          "startTime": "2023-01-22T06:00:00-06:00",
          "endTime": "2023-01-22T18:00:00-06:00",
          "isDaytime": true,
          "temperature": 22,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "5 mph",
          "windDirection": "W",
          "icon": "https://api.weather.gov/icons/land/day/sct?size=medium",
          "shortForecast": "Mostly Sunny",
          "detailedForecast": "Mostly sunny, with a high near 22. West wind around 5 mph.",
        },
        {
          "number": 6,
          "name": "Sunday Night",
          "startTime": "2023-01-22T18:00:00-06:00",
          "endTime": "2023-01-23T06:00:00-06:00",
          "isDaytime": false,
          "temperature": 13,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "5 to 10 mph",
          "windDirection": "SSW",
          "icon": "https://api.weather.gov/icons/land/night/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a low around 13. South southwest wind 5 to 10 mph.",
        },
        {
          "number": 7,
          "name": "Monday",
          "startTime": "2023-01-23T06:00:00-06:00",
          "endTime": "2023-01-23T18:00:00-06:00",
          "isDaytime": true,
          "temperature": 28,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 mph",
          "windDirection": "WSW",
          "icon": "https://api.weather.gov/icons/land/day/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a high near 28.",
        },
        {
          "number": 8,
          "name": "Monday Night",
          "startTime": "2023-01-23T18:00:00-06:00",
          "endTime": "2023-01-24T06:00:00-06:00",
          "isDaytime": false,
          "temperature": 12,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 mph",
          "windDirection": "WNW",
          "icon": "https://api.weather.gov/icons/land/night/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a low around 12.",
        },
        {
          "number": 9,
          "name": "Tuesday",
          "startTime": "2023-01-24T06:00:00-06:00",
          "endTime": "2023-01-24T18:00:00-06:00",
          "isDaytime": true,
          "temperature": 20,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 mph",
          "windDirection": "WNW",
          "icon": "https://api.weather.gov/icons/land/day/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a high near 20.",
        },
        {
          "number": 10,
          "name": "Tuesday Night",
          "startTime": "2023-01-24T18:00:00-06:00",
          "endTime": "2023-01-25T06:00:00-06:00",
          "isDaytime": false,
          "temperature": 8,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 mph",
          "windDirection": "WNW",
          "icon": "https://api.weather.gov/icons/land/night/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a low around 8.",
        },
        {
          "number": 11,
          "name": "Wednesday",
          "startTime": "2023-01-25T06:00:00-06:00",
          "endTime": "2023-01-25T18:00:00-06:00",
          "isDaytime": true,
          "temperature": 21,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 mph",
          "windDirection": "NW",
          "icon": "https://api.weather.gov/icons/land/day/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a high near 21.",
        },
        {
          "number": 12,
          "name": "Wednesday Night",
          "startTime": "2023-01-25T18:00:00-06:00",
          "endTime": "2023-01-26T06:00:00-06:00",
          "isDaytime": false,
          "temperature": 9,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 mph",
          "windDirection": "NW",
          "icon": "https://api.weather.gov/icons/land/night/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a low around 9.",
        },
        {
          "number": 13,
          "name": "Thursday",
          "startTime": "2023-01-26T06:00:00-06:00",
          "endTime": "2023-01-26T18:00:00-06:00",
          "isDaytime": true,
          "temperature": 19,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 to 15 mph",
          "windDirection": "NW",
          "icon": "https://api.weather.gov/icons/land/day/bkn?size=medium",
          "shortForecast": "Partly Sunny",
          "detailedForecast": "Partly sunny, with a high near 19.",
        },
        {
          "number": 14,
          "name": "Thursday Night",
          "startTime": "2023-01-26T18:00:00-06:00",
          "endTime": "2023-01-27T06:00:00-06:00",
          "isDaytime": false,
          "temperature": 7,
          "temperatureUnit": "F",
          "temperatureTrend": null,
          "windSpeed": "10 mph",
          "windDirection": "WNW",
          "icon": "https://api.weather.gov/icons/land/night/bkn?size=medium",
          "shortForecast": "Mostly Cloudy",
          "detailedForecast": "Mostly cloudy, with a low around 7.",
        },
      ],
    },
  },
  "status": 200,
  "statusText": "",
  "headers": {
    "cache-control": "public, max-age=3554, s-maxage=3600",
    "content-length": "1356",
    "content-type": "application/geo+json",
    "expires": "Fri, 20 Jan 2023 18:52:31 GMT",
    "x-correlation-id": "cf96e119",
    "x-request-id": "babb0b11-1fcf-4f72-a2da-660025a00122",
    "x-server-id": "vm-bldr-nids-apiapp6.ncep.noaa.gov",
  },
  "config": {
    "transitional": {
      "silentJSONParsing": true,
      "forcedJSONParsing": true,
      "clarifyTimeoutError": false,
    },
    "transformRequest": [null],
    "transformResponse": [null],
    "timeout": 0,
    "xsrfCookieName": "XSRF-TOKEN",
    "xsrfHeaderName": "X-XSRF-TOKEN",
    "maxContentLength": -1,
    "maxBodyLength": -1,
    "env": {
      "FormData": null,
    },
    "headers": {
      "Accept": "application/json, text/plain, */*",
    },
    "method": "get",
    "url": "https://api.weather.gov/gridpoints/FSD/99,65/forecast",
  },
  "request": {},
};

export default data;

//forecast.data.properties.temperature.values[index].value
