import React from "react";
import Day from "./Day";
import * as Helper from "../Helper/HelperFunctions";

export default function DaysSection({ forecast, loaded, basicForecast, fahrenheit }) {
  let MinMax = [];
  let dayMin = 200;
  let dayMax = -100;

  if (loaded) {
    for (let i = 0; i < 7; i++) {
      let min = Helper.CtoF(forecast.data.properties.minTemperature.values[i].value, fahrenheit);
      // let min = -100;
      let max = Helper.CtoF(forecast.data.properties.maxTemperature.values[i].value, fahrenheit);
      // let max = 200;
      if (dayMin > min) dayMin = min;
      if (dayMax < max) dayMax = max;
      MinMax[i] = {
        "Min": min,
        "Max": max,
      };
    }
    // forecast.data.properties.maxTemperature.values.map((items, index) => {
    //   let min = Helper.CtoF(forecast.data.properties.minTemperature.values[index].value, fahrenheit);
    //   // let min = -100;
    //   let max = Helper.CtoF(items.value, fahrenheit);
    //   // let max = 200;
    //   if (dayMin > min) dayMin = min;
    //   if (dayMax < max) dayMax = max;
    //   return (MinMax[index] = {
    //     "Min": min,
    //     "Max": max,
    //   });
    // });
  }

  let date = new Date();
  date.setDate(date.getDate() - 1);

  return (
    <div className="flex flex-col gap-2 CardContent">
      {MinMax.map((items, index) => {
        date.setDate(date.getDate() + 1);
        return <Day date={date.getDay()} MinMax={items} basicForecast={basicForecast} loaded={loaded} dayMax={Math.ceil(dayMax)} dayMin={Math.ceil(dayMin)} key={index} />;
      })}
    </div>
  );
}
