import React from "react";
import * as Helper from "../Helper/HelperFunctions";

export default function TempSection({ forecast, loaded, basicForecast, fahrenheit }) {
  let GeneralIndex = Helper.basicIndex(basicForecast.data.properties.periods, loaded);
  let TempIndex = Helper.advanceIndex(forecast.data.properties.temperature.values, loaded);

  return (
    <div className=" sticky top-0 flex flex-col justify-start items-center z-40 mb-2">
      <span className="text-7xl text-white">{Helper.CtoF(forecast.data.properties.temperature.values[TempIndex].value, fahrenheit)}&#176;</span>
      <span>{basicForecast.data.properties.periods[GeneralIndex].shortForecast}</span>
    </div>
  );
}
