import React from "react";
import Snow from "../Images/Snow.gif";
import Rain from "../Images/Rain.png";
import Fog from "../Images/Fog.png";
import Cloud from "../Images/Cloud.png";
import Thunder from "../Images/Thunder.png";
import Sunny from "../Images/Sunny.png";
import * as Helper from "../Helper/HelperFunctions";

export default function Background({ basicForecast, loaded }) {
  let weatherPriority = ["storm", "snow", "rain", "fog", "cloudy", "sunny"];
  let weatherBackground = [Thunder, Snow, Rain, Fog, Cloud, Sunny];
  let GeneralIndex;
  let Background = Sunny;
  if (loaded) GeneralIndex = Helper.basicIndex(basicForecast.data.properties.periods, loaded);

  if (loaded) {
    for (let i = 0; i < weatherPriority.length; i++) {
      if (basicForecast.data.properties.periods[GeneralIndex].detailedForecast.toLowerCase().includes(weatherPriority[i])) {
        Background = weatherBackground[i];
        break;
      }
    }
  }

  return (
    <div className="fixed w-full h-full -z-10 lg:hidden top-0">
      <img className="w-full h-full object-cover object-bottom" src={Background} alt="" />
    </div>
  );

  //snow
}
