import React from "react";

export default function Right({ areas, setAreas }) {
  function handleClick() {
    let temp = areas[0];
    let tempAreas = new Array(areas.length);
    let CurrentArray = areas;
    let index = 0;
    areas.forEach((element) => {
      if (areas.length === 1) {
        tempAreas = CurrentArray;
      }
      if (index === 0) {
        tempAreas[index] = CurrentArray[index + 1];
      } else if (index === areas.length - 1) {
        tempAreas[index] = temp;
      } else {
        tempAreas[index] = CurrentArray[index + 1];
      }
      index = index + 1;
    });
    setAreas(tempAreas);
    // localStorage.setItem("LocationList", JSON.stringify(tempAreas));
  }

  return (
    <div id="RightArrow" className="fixed w-4 h-screen top-0 right-0 flex flex-row justify-center items-center p-4 z-20 opacity-100 transition duration-300 ease-in-out" onClick={handleClick}>
      <span className="text-white">&#10095;</span>
    </div>
  );
}
