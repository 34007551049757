import React from "react";
// import * as Helper from "../Helper/HelperFunctions";

export default function Hourly({ date, MinMax, forecast, loaded, DailyArray, Temp, fahrenheit, ArrayLength }) {
  //   let currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() + date);

  let newDailyArray = new Array(ArrayLength);
  if (loaded) {
    for (let i = 0; i < DailyArray.length; i++) {
      if (DailyArray[i] === Temp) newDailyArray[i] = "T";
      else newDailyArray[i] = 0;
    }
  }

  let AmPm = "AM";
  if (date >= 12) AmPm = "PM";

  if (date > 0 && date <= 12) {
  } else if (date > 12) {
    date = date - 12;
  } else if (date === 0) {
    date = 12;
  }

  return (
    <div className="flex flex-col gap-2 justify-end items-center">
      {/* <span>{Math.ceil(MinMax.Min)}&#176;</span> */}
      <span>{Temp}&#176;</span>

      <span>
        <div id="bar" className="border border-white rounded-full w-2 h-16">
          <div id="progressBar" className="h-full flex flex-col-reverse justify-evenly items-center">
            {newDailyArray.map((item, index) => {
              if (item === "T") return <div className="w-full h-full bg-green-500" key={index}></div>;
              else return <div className="h-full w-full" key={index}></div>;
            })}
          </div>
        </div>
      </span>
      <span>
        {date}
        <span className="text-xs">{AmPm}</span>
      </span>
    </div>
  );
}
