// //
// INDEX OF ARRAY BASED ON CURRENT TIME OF DAY FROM API
// //

//MUST PASS IN ARRAY END POINT, NOT FULL JSON DATA SET ---- IF Array.??? = validTime
export function advanceIndex(array, loaded) {
  if (loaded === false) return 0;
  const currentDate = new Date();
  let TempTimeCheck = Math.abs(new Date(array[0].validTime.split("/")[0]) - currentDate);
  let TempIndex = 0;
  let j = 0;
  for (let i = 0; i < array.length; i++) {
    let checkDateValue = Math.abs(new Date(array[i].validTime.split("/")[0]) - currentDate);
    if (checkDateValue < TempTimeCheck) {
      TempIndex = i;
      TempTimeCheck = checkDateValue;
    }
    j = j + 1;
    if (j > 20) {
      i = array.length + 1;
    }
  }
  return TempIndex;
}

//MUST PASS IN ARRAY END POINT, NOT FULL JSON DATA SET ---- IF Array.??? = endTime
export function basicIndex(array, loaded) {
  if (loaded === false) return 0;
  const currentDate = new Date();
  let TempTimeCheck = Math.abs(new Date(array[0].endTime.split("/")[0]) - currentDate);
  let TempIndex = 0;
  let j = 0;
  for (let i = 0; i < array.length; i++) {
    let checkDateValue = Math.abs(new Date(array[i].endTime.split("/")[0]) - currentDate);
    if (checkDateValue < TempTimeCheck) {
      TempIndex = i;
      TempTimeCheck = checkDateValue;
    }
    j = j + 1;
    if (j > 20) {
      i = array.length + 1;
    }
  }
  return TempIndex;
}

//MUST PASS IN ARRAY END POINT, NOT FULL JSON DATA SET ---- IF Array.??? = endTime
export function basicIndexWithSetDate(array, loaded, currentDate) {
  if (loaded === false) return 0;
  let TempTimeCheck = Math.abs(new Date(array[0].endTime.split("/")[0]) - currentDate);
  let TempIndex = 0;
  let j = 0;
  for (let i = 0; i < array.length; i++) {
    let checkDateValue = Math.abs(new Date(array[i].endTime.split("/")[0]) - currentDate);
    if (checkDateValue < TempTimeCheck) {
      TempIndex = i;
      TempTimeCheck = checkDateValue;
    }
    j = j + 1;
    if (j > 20) {
      i = array.length + 1;
    }
  }
  return TempIndex;
}

// //
// F OR C BASED ON USER INPUT
// //

//RETURNS VALUED BASED ON APP.JS COMPONENT ForC.js
export function CtoF(Temp, fahrenheit) {
  if (fahrenheit === false) return Temp.toFixed(2);
  return (1.8 * Temp + 32).toFixed(0);
}

// //
// KPH OR MPH BASED ON USER INPUT
// //

export function KtoM(Speed, Loaded) {
  if (Loaded) return Math.ceil(Speed / 1.609);
  else return 0;
}

// //
// GET TIME AND DATE FROM INPUT
// //

//RETURNS TIME BASED ON LOCAL TIME TO STANDARD TIME
export function LocalTime(array, loaded) {
  if (loaded === false) return "Loading";
  let index = advanceIndex(array, loaded);
  if (index !== 0) index = index - 1;
  let currentTime = array[index].validTime.split("/")[0];
  let time = new Date(currentTime).toLocaleTimeString();
  return time;
}

//RETURNS DATE BASED ON LOCAL TIME TO STANDARD TIME
export function LocalDate(array, loaded) {
  if (loaded === false) return "Loading";
  let index = advanceIndex(array, loaded);
  let currentTime = array[index].validTime.split("/")[0];
  let time = new Date(currentTime).toLocaleDateString();
  return time;
}

export function GetHourFromDate(date, loaded) {
  if (loaded === false) return 0;
  let newDate = new Date(date.split("/")[0]);
  return newDate.getHours();
}

//
// FUNCTION TO COVERT FORCAST DATA TO A MORE READABLE FORMAT
//

export function cleanText(value) {
  const text = value;
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

// function to change mm to inches with inces symbol
export function mmToInches(mm) {
  return (mm / 25.4).toFixed(1) + " in";
}

// function to change m to ft with ft symbol
export function mToFt(m) {
  return (m * 3.28084).toFixed(0) + " ft";
}

// function km per hour to miles per hour with mph symbol
export function kphToMph(kph) {
  return (kph / 1.609).toFixed(0) + " mph";
}

//function to change c to f with degree symbol
export function cToF(c, fahrenheit) {
  if (c === null) return "--";
  if (fahrenheit === false) return c.toFixed(1) + "°";
  return (c * 1.8 + 32).toFixed(0) + "°";
}
