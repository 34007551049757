import React, { useState } from "react";
import "./Icon.css";
import Add from "./Comp/Add";
import Card from "./Comp/Card";
import CtoF from "./Comp/CtoF";
import Left from "./Comp/Left";
import Right from "./Comp/Right";
import Dots from "./Comp/Dots";
import QR from "./Images/QR.png";
import BaseLocationList from "./Schema/BaseLocationList";
// import Background from "./Comp/Background";

function App() {
  const [areas, setAreas] = useState([]);
  const [fahrenheit, setFahrenheit] = useState(true);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  let dotIndex = 0;

  React.useEffect(() => {
    setAreas(JSON.parse(localStorage.getItem("LocationList")) || BaseLocationList);
  }, []);

  let CardArray = new Array(3);

  // function reportWidth() {
  //   setWindowSize(window.innerWidth);
  // }

  // window.onresize = reportWidth();

  window.addEventListener("resize", function () {
    setWindowSize(window.innerWidth);
  });

  if (windowSize < 1000) {
    areas.map((item, index) => {
      if (index === 0) {
        CardArray[index] = <Card setAreas={setAreas} areas={item} areasList={areas} key={index} index={index} fahrenheit={fahrenheit} setFahrenheit={setFahrenheit} />;
        dotIndex = item.Index;
      }
      return null;
    });
  } else if (windowSize >= 1000) {
    areas.map((item, index) => {
      if (index === 0 || index === 1 || index === 2) {
        CardArray[index] = <Card setAreas={setAreas} areas={item} areasList={areas} key={index} index={index} fahrenheit={fahrenheit} setFahrenheit={setFahrenheit} />;
      }
      if (index === 2) {
        dotIndex = item.Index;
      }
      return null;
    });
  }

  return (
    <>
      <div className="hidden lg:flex fixed bottom-0 left-0 w-auto h-auto bg-gray-700 flex-col justify-center items-center m-6">
        <span className="text-white">Mobile First</span>
        <img className="w-24 h-24" src={QR} alt="Mobile First" />
      </div>
      <div className="absolute top-0 left-0 flex flex-row justify-center items-center w-screen h-auto">
        {/* {areas.map((item, index) => {
        if (index === 0) {
          return <Background areas={item.State} key={index} />;
        }
        return null;
      })} */}
        {/* <Background /> */}

        <Add setAreas={setAreas} />
        <CtoF fahrenheit={fahrenheit} setFahrenheit={setFahrenheit} />
        <Right areas={areas} setAreas={setAreas} />
        <Left areas={areas} setAreas={setAreas} />
        <div className="relative top-0 left-0 w-full flex flex-row justify-center">
          {CardArray.map((item, index) => {
            return item;
          })}
        </div>
        <Dots dotIndex={dotIndex} />
      </div>
    </>
  );
}

export default App;
