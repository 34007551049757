let AdvanceForcast = {
  "data": {
    "@context": [
      "https://geojson.org/geojson-ld/geojson-context.jsonld",
      {
        "@version": "1.1",
        "wmoUnit": "https://codes.wmo.int/common/unit/",
        "nwsUnit": "https://api.weather.gov/ontology/unit/",
      },
    ],
    "id": "https://api.weather.gov/gridpoints/FSD/99,65",
    "type": "Feature",
    "geometry": {
      "type": "Polygon",
      "coordinates": [
        [
          [-96.6836577, 43.5908735],
          [-96.6832878, 43.569294400000004],
          [-96.6534984, 43.569560100000004],
          [-96.6538618, 43.5911392],
          [-96.6836577, 43.5908735],
        ],
      ],
    },
    "properties": {
      "@id": "https://api.weather.gov/gridpoints/FSD/99,65",
      "@type": "wx:Gridpoint",
      "updateTime": "2023-01-20T16:44:55+00:00",
      "validTimes": "2023-01-20T10:00:00+00:00/P7DT15H",
      "elevation": {
        "unitCode": "wmoUnit:m",
        "value": 398.9832,
      },
      "forecastOffice": "https://api.weather.gov/offices/FSD",
      "gridId": "FSD",
      "gridX": "99",
      "gridY": "65",
      "temperature": {
        "uom": "wmoUnit:degC",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT3H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT5H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-20T21:00:00+00:00/PT2H",
            "value": -3.888888888888889,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT2H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT2H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT3H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT2H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT4H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-21T15:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": -3.3333333333333335,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT1H",
            "value": -2.7777777777777777,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT2H",
            "value": -2.2222222222222223,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": -2.7777777777777777,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": -3.888888888888889,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": -5,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT4H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-22T07:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-22T08:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-22T09:00:00+00:00/PT2H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-22T11:00:00+00:00/PT2H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T13:00:00+00:00/PT3H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-22T16:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T17:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-22T19:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-22T20:00:00+00:00/PT2H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-22T22:00:00+00:00/PT1H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-22T23:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-23T01:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT2H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-23T04:00:00+00:00/PT4H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-23T08:00:00+00:00/PT7H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-23T15:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-23T16:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-23T18:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-23T19:00:00+00:00/PT1H",
            "value": -3.3333333333333335,
          },
          {
            "validTime": "2023-01-23T20:00:00+00:00/PT1H",
            "value": -2.7777777777777777,
          },
          {
            "validTime": "2023-01-23T21:00:00+00:00/PT2H",
            "value": -2.2222222222222223,
          },
          {
            "validTime": "2023-01-23T23:00:00+00:00/PT1H",
            "value": -2.7777777777777777,
          },
          {
            "validTime": "2023-01-24T00:00:00+00:00/PT1H",
            "value": -3.3333333333333335,
          },
          {
            "validTime": "2023-01-24T01:00:00+00:00/PT2H",
            "value": -3.888888888888889,
          },
          {
            "validTime": "2023-01-24T03:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-24T04:00:00+00:00/PT2H",
            "value": -5,
          },
          {
            "validTime": "2023-01-24T06:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-24T07:00:00+00:00/PT2H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-24T09:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-24T10:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-24T11:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-24T12:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-24T13:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-24T14:00:00+00:00/PT2H",
            "value": -10,
          },
          {
            "validTime": "2023-01-24T16:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-24T17:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-24T18:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-24T19:00:00+00:00/PT3H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-24T23:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-25T04:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-25T06:00:00+00:00/PT9H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-25T15:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-25T16:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-25T17:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-25T18:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-25T19:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-25T20:00:00+00:00/PT3H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-25T23:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-26T00:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-26T01:00:00+00:00/PT2H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-26T03:00:00+00:00/PT2H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-26T05:00:00+00:00/PT2H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-26T07:00:00+00:00/PT2H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-26T09:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-26T10:00:00+00:00/PT2H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-26T12:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-26T13:00:00+00:00/PT3H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-26T16:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-26T17:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-26T18:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-26T19:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-26T20:00:00+00:00/PT2H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-26T22:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-26T23:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-27T01:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-27T02:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-27T03:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-27T04:00:00+00:00/PT7H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-27T11:00:00+00:00/PT4H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-27T15:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-27T16:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-27T17:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-27T18:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-27T19:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT3H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-27T23:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-28T00:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
        ],
      },
      "dewpoint": {
        "uom": "wmoUnit:degC",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT2H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT3H",
            "value": -5,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT1H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT2H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT2H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT6H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT4H",
            "value": -3.888888888888889,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT4H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-22T07:00:00+00:00/PT2H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-22T09:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T10:00:00+00:00/PT2H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-22T12:00:00+00:00/PT4H",
            "value": -10,
          },
          {
            "validTime": "2023-01-22T16:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-22T17:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-22T19:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-22T20:00:00+00:00/PT2H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-22T22:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-22T23:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-23T01:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT13H",
            "value": -10,
          },
          {
            "validTime": "2023-01-23T15:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-23T16:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-23T18:00:00+00:00/PT1H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-23T19:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-23T20:00:00+00:00/PT3H",
            "value": -3.888888888888889,
          },
          {
            "validTime": "2023-01-23T23:00:00+00:00/PT1H",
            "value": -4.444444444444445,
          },
          {
            "validTime": "2023-01-24T00:00:00+00:00/PT2H",
            "value": -5,
          },
          {
            "validTime": "2023-01-24T02:00:00+00:00/PT2H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-24T04:00:00+00:00/PT2H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-24T06:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-24T07:00:00+00:00/PT2H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-24T09:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-24T10:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-24T11:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-24T12:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-24T13:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-24T14:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-24T16:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-24T17:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-24T18:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-24T19:00:00+00:00/PT2H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-24T21:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-24T23:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-25T03:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-25T04:00:00+00:00/PT2H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-25T06:00:00+00:00/PT8H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-25T14:00:00+00:00/PT1H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-25T15:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-25T16:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-25T17:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-25T18:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-25T19:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-25T20:00:00+00:00/PT3H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-25T23:00:00+00:00/PT3H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-26T02:00:00+00:00/PT2H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-26T04:00:00+00:00/PT2H",
            "value": -10,
          },
          {
            "validTime": "2023-01-26T06:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-26T07:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-26T09:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-26T10:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-26T11:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-26T12:00:00+00:00/PT4H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-26T16:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-26T17:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-26T18:00:00+00:00/PT4H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-26T22:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-26T23:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-27T01:00:00+00:00/PT2H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-27T03:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-27T04:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-27T05:00:00+00:00/PT3H",
            "value": -15,
          },
          {
            "validTime": "2023-01-27T08:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-27T09:00:00+00:00/PT5H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-27T14:00:00+00:00/PT1H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-27T15:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-27T16:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-27T17:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-27T18:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-27T19:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT3H",
            "value": -10,
          },
          {
            "validTime": "2023-01-27T23:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-28T00:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
        ],
      },
      "maxTemperature": {
        "uom": "wmoUnit:degC",
        "values": [
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT13H",
            "value": -3.3333333333333335,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT13H",
            "value": -1.6666666666666667,
          },
          {
            "validTime": "2023-01-22T13:00:00+00:00/PT13H",
            "value": -5.555555555555555,
          },
          {
            "validTime": "2023-01-23T13:00:00+00:00/PT13H",
            "value": -2.2222222222222223,
          },
          {
            "validTime": "2023-01-24T13:00:00+00:00/PT13H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-25T13:00:00+00:00/PT13H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-26T13:00:00+00:00/PT13H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-27T13:00:00+00:00/PT13H",
            "value": -3.888888888888889,
          },
        ],
      },
      "minTemperature": {
        "uom": "wmoUnit:degC",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT5H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT14H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT14H",
            "value": -10,
          },
          {
            "validTime": "2023-01-23T01:00:00+00:00/PT14H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-24T01:00:00+00:00/PT14H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT14H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-26T01:00:00+00:00/PT14H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-27T01:00:00+00:00/PT14H",
            "value": -13.88888888888889,
          },
        ],
      },
      "relativeHumidity": {
        "uom": "wmoUnit:percent",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT2H",
            "value": 88,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT2H",
            "value": 92,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT1H",
            "value": 89,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT1H",
            "value": 96,
          },
          {
            "validTime": "2023-01-20T21:00:00+00:00/PT4H",
            "value": 92,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT1H",
            "value": 96,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT1H",
            "value": 100,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT2H",
            "value": 96,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT2H",
            "value": 92,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT2H",
            "value": 84,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT2H",
            "value": 92,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT4H",
            "value": 96,
          },
          {
            "validTime": "2023-01-21T15:00:00+00:00/PT5H",
            "value": 92,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT2H",
            "value": 88,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT2H",
            "value": 96,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT7H",
            "value": 92,
          },
          {
            "validTime": "2023-01-22T08:00:00+00:00/PT2H",
            "value": 96,
          },
          {
            "validTime": "2023-01-22T10:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-22T11:00:00+00:00/PT1H",
            "value": 96,
          },
          {
            "validTime": "2023-01-22T12:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-22T13:00:00+00:00/PT5H",
            "value": 96,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT2H",
            "value": 92,
          },
          {
            "validTime": "2023-01-22T20:00:00+00:00/PT4H",
            "value": 88,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT4H",
            "value": 92,
          },
          {
            "validTime": "2023-01-23T04:00:00+00:00/PT4H",
            "value": 96,
          },
          {
            "validTime": "2023-01-23T08:00:00+00:00/PT13H",
            "value": 92,
          },
          {
            "validTime": "2023-01-23T21:00:00+00:00/PT4H",
            "value": 88,
          },
          {
            "validTime": "2023-01-24T01:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-24T02:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-24T03:00:00+00:00/PT15H",
            "value": 92,
          },
          {
            "validTime": "2023-01-24T18:00:00+00:00/PT3H",
            "value": 88,
          },
          {
            "validTime": "2023-01-24T21:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT3H",
            "value": 88,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT1H",
            "value": 91,
          },
          {
            "validTime": "2023-01-25T03:00:00+00:00/PT11H",
            "value": 87,
          },
          {
            "validTime": "2023-01-25T14:00:00+00:00/PT3H",
            "value": 91,
          },
          {
            "validTime": "2023-01-25T17:00:00+00:00/PT2H",
            "value": 88,
          },
          {
            "validTime": "2023-01-25T19:00:00+00:00/PT5H",
            "value": 84,
          },
          {
            "validTime": "2023-01-26T00:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-26T01:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-26T02:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-26T03:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-26T04:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-26T05:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-26T06:00:00+00:00/PT4H",
            "value": 88,
          },
          {
            "validTime": "2023-01-26T10:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-26T11:00:00+00:00/PT2H",
            "value": 84,
          },
          {
            "validTime": "2023-01-26T13:00:00+00:00/PT4H",
            "value": 87,
          },
          {
            "validTime": "2023-01-26T17:00:00+00:00/PT2H",
            "value": 88,
          },
          {
            "validTime": "2023-01-26T19:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-26T20:00:00+00:00/PT4H",
            "value": 80,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT2H",
            "value": 84,
          },
          {
            "validTime": "2023-01-27T02:00:00+00:00/PT3H",
            "value": 87,
          },
          {
            "validTime": "2023-01-27T05:00:00+00:00/PT3H",
            "value": 83,
          },
          {
            "validTime": "2023-01-27T08:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-27T09:00:00+00:00/PT2H",
            "value": 91,
          },
          {
            "validTime": "2023-01-27T11:00:00+00:00/PT3H",
            "value": 87,
          },
          {
            "validTime": "2023-01-27T14:00:00+00:00/PT2H",
            "value": 91,
          },
          {
            "validTime": "2023-01-27T16:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-27T17:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-27T18:00:00+00:00/PT7H",
            "value": 84,
          },
        ],
      },
      "apparentTemperature": {
        "uom": "wmoUnit:degC",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT5H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT3H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT2H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T15:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T21:00:00+00:00/PT1H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-22T05:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-22T07:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-22T09:00:00+00:00/PT2H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-22T11:00:00+00:00/PT2H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-22T13:00:00+00:00/PT2H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-22T15:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-22T16:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-22T17:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-22T19:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-22T20:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T21:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-22T22:00:00+00:00/PT2H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-23T01:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT2H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-23T04:00:00+00:00/PT7H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-23T11:00:00+00:00/PT3H",
            "value": -15,
          },
          {
            "validTime": "2023-01-23T14:00:00+00:00/PT1H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-23T15:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-23T16:00:00+00:00/PT1H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-23T18:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-23T19:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-23T20:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-23T21:00:00+00:00/PT2H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-23T23:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-24T00:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-24T01:00:00+00:00/PT2H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-24T03:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-24T04:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-24T06:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-24T07:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-24T09:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-24T10:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-24T11:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-24T12:00:00+00:00/PT1H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-24T13:00:00+00:00/PT3H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-24T16:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-24T17:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-24T18:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-24T19:00:00+00:00/PT3H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-24T23:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT2H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-25T04:00:00+00:00/PT2H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-25T06:00:00+00:00/PT2H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-25T08:00:00+00:00/PT6H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-25T14:00:00+00:00/PT1H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-25T15:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-25T16:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-25T17:00:00+00:00/PT1H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-25T18:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-25T19:00:00+00:00/PT5H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-26T00:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-26T01:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-26T02:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-26T03:00:00+00:00/PT4H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-26T07:00:00+00:00/PT2H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-26T09:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-26T10:00:00+00:00/PT1H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-26T11:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-26T12:00:00+00:00/PT1H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-26T13:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-26T14:00:00+00:00/PT2H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-26T16:00:00+00:00/PT1H",
            "value": -18.333333333333332,
          },
          {
            "validTime": "2023-01-26T17:00:00+00:00/PT1H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-26T18:00:00+00:00/PT2H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-26T20:00:00+00:00/PT2H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-26T22:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-26T23:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT1H",
            "value": -18.333333333333332,
          },
          {
            "validTime": "2023-01-27T01:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-27T02:00:00+00:00/PT1H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-27T03:00:00+00:00/PT1H",
            "value": -20,
          },
          {
            "validTime": "2023-01-27T04:00:00+00:00/PT12H",
            "value": -20.555555555555557,
          },
          {
            "validTime": "2023-01-27T16:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-27T17:00:00+00:00/PT1H",
            "value": -18.333333333333332,
          },
          {
            "validTime": "2023-01-27T18:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-27T19:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT3H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-27T23:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-28T00:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
        ],
      },
      "heatIndex": {
        "uom": "wmoUnit:degC",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/P7DT15H",
            "value": null,
          },
        ],
      },
      "windChill": {
        "uom": "wmoUnit:degC",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT5H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT3H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT2H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-21T15:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT1H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T21:00:00+00:00/PT1H",
            "value": -6.111111111111111,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": -6.666666666666667,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": -7.777777777777778,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-22T05:00:00+00:00/PT2H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-22T07:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-22T09:00:00+00:00/PT2H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-22T11:00:00+00:00/PT2H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-22T13:00:00+00:00/PT2H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-22T15:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-22T16:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-22T17:00:00+00:00/PT1H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-22T19:00:00+00:00/PT1H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-22T20:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-22T21:00:00+00:00/PT1H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-22T22:00:00+00:00/PT2H",
            "value": -10.555555555555555,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-23T01:00:00+00:00/PT1H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT2H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-23T04:00:00+00:00/PT7H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-23T11:00:00+00:00/PT3H",
            "value": -15,
          },
          {
            "validTime": "2023-01-23T14:00:00+00:00/PT1H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-23T15:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-23T16:00:00+00:00/PT1H",
            "value": -13.333333333333334,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-23T18:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-23T19:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-23T20:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-23T21:00:00+00:00/PT2H",
            "value": -7.222222222222222,
          },
          {
            "validTime": "2023-01-23T23:00:00+00:00/PT1H",
            "value": -8.333333333333334,
          },
          {
            "validTime": "2023-01-24T00:00:00+00:00/PT1H",
            "value": -8.88888888888889,
          },
          {
            "validTime": "2023-01-24T01:00:00+00:00/PT2H",
            "value": -9.444444444444445,
          },
          {
            "validTime": "2023-01-24T03:00:00+00:00/PT1H",
            "value": -10,
          },
          {
            "validTime": "2023-01-24T04:00:00+00:00/PT2H",
            "value": -11.11111111111111,
          },
          {
            "validTime": "2023-01-24T06:00:00+00:00/PT1H",
            "value": -11.666666666666666,
          },
          {
            "validTime": "2023-01-24T07:00:00+00:00/PT2H",
            "value": -12.222222222222221,
          },
          {
            "validTime": "2023-01-24T09:00:00+00:00/PT1H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-24T10:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-24T11:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-24T12:00:00+00:00/PT1H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-24T13:00:00+00:00/PT3H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-24T16:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-24T17:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-24T18:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-24T19:00:00+00:00/PT3H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-24T23:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT2H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-25T04:00:00+00:00/PT2H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-25T06:00:00+00:00/PT2H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-25T08:00:00+00:00/PT6H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-25T14:00:00+00:00/PT1H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-25T15:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-25T16:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-25T17:00:00+00:00/PT1H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-25T18:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-25T19:00:00+00:00/PT5H",
            "value": -12.777777777777779,
          },
          {
            "validTime": "2023-01-26T00:00:00+00:00/PT1H",
            "value": -13.88888888888889,
          },
          {
            "validTime": "2023-01-26T01:00:00+00:00/PT1H",
            "value": -14.444444444444445,
          },
          {
            "validTime": "2023-01-26T02:00:00+00:00/PT1H",
            "value": -15,
          },
          {
            "validTime": "2023-01-26T03:00:00+00:00/PT4H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-26T07:00:00+00:00/PT2H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-26T09:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-26T10:00:00+00:00/PT1H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-26T11:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-26T12:00:00+00:00/PT1H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-26T13:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-26T14:00:00+00:00/PT2H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-26T16:00:00+00:00/PT1H",
            "value": -18.333333333333332,
          },
          {
            "validTime": "2023-01-26T17:00:00+00:00/PT1H",
            "value": -17.77777777777778,
          },
          {
            "validTime": "2023-01-26T18:00:00+00:00/PT2H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-26T20:00:00+00:00/PT2H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-26T22:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-26T23:00:00+00:00/PT1H",
            "value": -17.22222222222222,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT1H",
            "value": -18.333333333333332,
          },
          {
            "validTime": "2023-01-27T01:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-27T02:00:00+00:00/PT1H",
            "value": -19.444444444444443,
          },
          {
            "validTime": "2023-01-27T03:00:00+00:00/PT1H",
            "value": -20,
          },
          {
            "validTime": "2023-01-27T04:00:00+00:00/PT12H",
            "value": -20.555555555555557,
          },
          {
            "validTime": "2023-01-27T16:00:00+00:00/PT1H",
            "value": -18.88888888888889,
          },
          {
            "validTime": "2023-01-27T17:00:00+00:00/PT1H",
            "value": -18.333333333333332,
          },
          {
            "validTime": "2023-01-27T18:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
          {
            "validTime": "2023-01-27T19:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT3H",
            "value": -15.555555555555555,
          },
          {
            "validTime": "2023-01-27T23:00:00+00:00/PT1H",
            "value": -16.11111111111111,
          },
          {
            "validTime": "2023-01-28T00:00:00+00:00/PT1H",
            "value": -16.666666666666668,
          },
        ],
      },
      "skyCover": {
        "uom": "wmoUnit:percent",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": 74,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT1H",
            "value": 73,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": 86,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT1H",
            "value": 90,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT1H",
            "value": 91,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT1H",
            "value": 89,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT1H",
            "value": 86,
          },
          {
            "validTime": "2023-01-20T21:00:00+00:00/PT1H",
            "value": 83,
          },
          {
            "validTime": "2023-01-20T22:00:00+00:00/PT1H",
            "value": 86,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": 89,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT1H",
            "value": 89,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT1H",
            "value": 90,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT1H",
            "value": 91,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT1H",
            "value": 92,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT1H",
            "value": 93,
          },
          {
            "validTime": "2023-01-21T08:00:00+00:00/PT1H",
            "value": 94,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": 95,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT1H",
            "value": 93,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT1H",
            "value": 91,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT1H",
            "value": 89,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-21T14:00:00+00:00/PT2H",
            "value": 87,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": 83,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": 81,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT1H",
            "value": 76,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT1H",
            "value": 72,
          },
          {
            "validTime": "2023-01-21T21:00:00+00:00/PT1H",
            "value": 67,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": 70,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": 73,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": 76,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": 67,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": 63,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT4H",
            "value": 59,
          },
          {
            "validTime": "2023-01-22T07:00:00+00:00/PT2H",
            "value": 58,
          },
          {
            "validTime": "2023-01-22T09:00:00+00:00/PT7H",
            "value": 57,
          },
          {
            "validTime": "2023-01-22T16:00:00+00:00/PT1H",
            "value": 54,
          },
          {
            "validTime": "2023-01-22T17:00:00+00:00/PT1H",
            "value": 50,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT1H",
            "value": 47,
          },
          {
            "validTime": "2023-01-22T19:00:00+00:00/PT1H",
            "value": 42,
          },
          {
            "validTime": "2023-01-22T20:00:00+00:00/PT1H",
            "value": 36,
          },
          {
            "validTime": "2023-01-22T21:00:00+00:00/PT1H",
            "value": 31,
          },
          {
            "validTime": "2023-01-22T22:00:00+00:00/PT1H",
            "value": 36,
          },
          {
            "validTime": "2023-01-22T23:00:00+00:00/PT1H",
            "value": 41,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT1H",
            "value": 47,
          },
          {
            "validTime": "2023-01-23T01:00:00+00:00/PT1H",
            "value": 56,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT1H",
            "value": 65,
          },
          {
            "validTime": "2023-01-23T03:00:00+00:00/PT3H",
            "value": 75,
          },
          {
            "validTime": "2023-01-23T06:00:00+00:00/PT1H",
            "value": 76,
          },
          {
            "validTime": "2023-01-23T07:00:00+00:00/PT1H",
            "value": 78,
          },
          {
            "validTime": "2023-01-23T08:00:00+00:00/PT1H",
            "value": 80,
          },
          {
            "validTime": "2023-01-23T09:00:00+00:00/PT1H",
            "value": 82,
          },
          {
            "validTime": "2023-01-23T10:00:00+00:00/PT1H",
            "value": 83,
          },
          {
            "validTime": "2023-01-23T11:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-23T12:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-23T13:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-23T14:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-23T15:00:00+00:00/PT1H",
            "value": 83,
          },
          {
            "validTime": "2023-01-23T16:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-23T18:00:00+00:00/PT1H",
            "value": 90,
          },
          {
            "validTime": "2023-01-23T19:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-23T20:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-23T21:00:00+00:00/PT1H",
            "value": 83,
          },
          {
            "validTime": "2023-01-23T22:00:00+00:00/PT1H",
            "value": 85,
          },
          {
            "validTime": "2023-01-23T23:00:00+00:00/PT1H",
            "value": 87,
          },
          {
            "validTime": "2023-01-24T00:00:00+00:00/PT4H",
            "value": 89,
          },
          {
            "validTime": "2023-01-24T04:00:00+00:00/PT1H",
            "value": 88,
          },
          {
            "validTime": "2023-01-24T05:00:00+00:00/PT1H",
            "value": 86,
          },
          {
            "validTime": "2023-01-24T06:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-24T07:00:00+00:00/PT1H",
            "value": 83,
          },
          {
            "validTime": "2023-01-24T08:00:00+00:00/PT1H",
            "value": 82,
          },
          {
            "validTime": "2023-01-24T09:00:00+00:00/PT4H",
            "value": 81,
          },
          {
            "validTime": "2023-01-24T13:00:00+00:00/PT1H",
            "value": 79,
          },
          {
            "validTime": "2023-01-24T14:00:00+00:00/PT1H",
            "value": 77,
          },
          {
            "validTime": "2023-01-24T15:00:00+00:00/PT1H",
            "value": 76,
          },
          {
            "validTime": "2023-01-24T16:00:00+00:00/PT1H",
            "value": 73,
          },
          {
            "validTime": "2023-01-24T17:00:00+00:00/PT1H",
            "value": 71,
          },
          {
            "validTime": "2023-01-24T18:00:00+00:00/PT1H",
            "value": 69,
          },
          {
            "validTime": "2023-01-24T19:00:00+00:00/PT1H",
            "value": 67,
          },
          {
            "validTime": "2023-01-24T20:00:00+00:00/PT1H",
            "value": 66,
          },
          {
            "validTime": "2023-01-24T21:00:00+00:00/PT1H",
            "value": 64,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT1H",
            "value": 65,
          },
          {
            "validTime": "2023-01-24T23:00:00+00:00/PT1H",
            "value": 66,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT1H",
            "value": 67,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT1H",
            "value": 68,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT1H",
            "value": 69,
          },
          {
            "validTime": "2023-01-25T03:00:00+00:00/PT1H",
            "value": 70,
          },
          {
            "validTime": "2023-01-25T04:00:00+00:00/PT1H",
            "value": 71,
          },
          {
            "validTime": "2023-01-25T05:00:00+00:00/PT1H",
            "value": 72,
          },
          {
            "validTime": "2023-01-25T06:00:00+00:00/PT1H",
            "value": 74,
          },
          {
            "validTime": "2023-01-25T07:00:00+00:00/PT1H",
            "value": 75,
          },
          {
            "validTime": "2023-01-25T08:00:00+00:00/PT1H",
            "value": 77,
          },
          {
            "validTime": "2023-01-25T09:00:00+00:00/PT1H",
            "value": 78,
          },
          {
            "validTime": "2023-01-25T10:00:00+00:00/PT1H",
            "value": 79,
          },
          {
            "validTime": "2023-01-25T11:00:00+00:00/PT4H",
            "value": 80,
          },
          {
            "validTime": "2023-01-25T15:00:00+00:00/PT5H",
            "value": 79,
          },
          {
            "validTime": "2023-01-25T20:00:00+00:00/PT1H",
            "value": 78,
          },
          {
            "validTime": "2023-01-25T21:00:00+00:00/PT1H",
            "value": 77,
          },
          {
            "validTime": "2023-01-25T22:00:00+00:00/PT1H",
            "value": 79,
          },
          {
            "validTime": "2023-01-25T23:00:00+00:00/PT1H",
            "value": 80,
          },
          {
            "validTime": "2023-01-26T00:00:00+00:00/PT4H",
            "value": 82,
          },
          {
            "validTime": "2023-01-26T04:00:00+00:00/PT4H",
            "value": 81,
          },
          {
            "validTime": "2023-01-26T08:00:00+00:00/PT2H",
            "value": 82,
          },
          {
            "validTime": "2023-01-26T10:00:00+00:00/PT1H",
            "value": 83,
          },
          {
            "validTime": "2023-01-26T11:00:00+00:00/PT1H",
            "value": 84,
          },
          {
            "validTime": "2023-01-26T12:00:00+00:00/PT2H",
            "value": 85,
          },
          {
            "validTime": "2023-01-26T14:00:00+00:00/PT2H",
            "value": 84,
          },
          {
            "validTime": "2023-01-26T16:00:00+00:00/PT1H",
            "value": 77,
          },
          {
            "validTime": "2023-01-26T17:00:00+00:00/PT1H",
            "value": 70,
          },
          {
            "validTime": "2023-01-26T18:00:00+00:00/PT1H",
            "value": 63,
          },
          {
            "validTime": "2023-01-26T19:00:00+00:00/PT1H",
            "value": 60,
          },
          {
            "validTime": "2023-01-26T20:00:00+00:00/PT1H",
            "value": 56,
          },
          {
            "validTime": "2023-01-26T21:00:00+00:00/PT1H",
            "value": 53,
          },
          {
            "validTime": "2023-01-26T22:00:00+00:00/PT1H",
            "value": 60,
          },
          {
            "validTime": "2023-01-26T23:00:00+00:00/PT1H",
            "value": 67,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT1H",
            "value": 74,
          },
          {
            "validTime": "2023-01-27T01:00:00+00:00/PT1H",
            "value": 75,
          },
          {
            "validTime": "2023-01-27T02:00:00+00:00/PT1H",
            "value": 76,
          },
          {
            "validTime": "2023-01-27T03:00:00+00:00/PT3H",
            "value": 77,
          },
          {
            "validTime": "2023-01-27T06:00:00+00:00/PT4H",
            "value": 78,
          },
          {
            "validTime": "2023-01-27T10:00:00+00:00/PT1H",
            "value": 77,
          },
          {
            "validTime": "2023-01-27T11:00:00+00:00/PT2H",
            "value": 76,
          },
          {
            "validTime": "2023-01-27T13:00:00+00:00/PT1H",
            "value": 68,
          },
          {
            "validTime": "2023-01-27T14:00:00+00:00/PT1H",
            "value": 60,
          },
          {
            "validTime": "2023-01-27T15:00:00+00:00/PT4H",
            "value": 53,
          },
          {
            "validTime": "2023-01-27T19:00:00+00:00/PT1H",
            "value": 50,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT1H",
            "value": 46,
          },
          {
            "validTime": "2023-01-27T21:00:00+00:00/PT1H",
            "value": 42,
          },
          {
            "validTime": "2023-01-27T22:00:00+00:00/PT1H",
            "value": 46,
          },
          {
            "validTime": "2023-01-27T23:00:00+00:00/PT1H",
            "value": 49,
          },
          {
            "validTime": "2023-01-28T00:00:00+00:00/PT1H",
            "value": 52,
          },
        ],
      },
      "windDirection": {
        "uom": "wmoUnit:degree_(angle)",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": 290,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT2H",
            "value": 330,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": 210,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": 60,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT1H",
            "value": 250,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT1H",
            "value": 210,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT1H",
            "value": 220,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT1H",
            "value": 230,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT3H",
            "value": 220,
          },
          {
            "validTime": "2023-01-20T22:00:00+00:00/PT1H",
            "value": 230,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": 250,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT5H",
            "value": 260,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT4H",
            "value": 270,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT3H",
            "value": 260,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT2H",
            "value": 250,
          },
          {
            "validTime": "2023-01-21T14:00:00+00:00/PT3H",
            "value": 240,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT4H",
            "value": 250,
          },
          {
            "validTime": "2023-01-21T21:00:00+00:00/PT4H",
            "value": 260,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": 270,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": 280,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT1H",
            "value": 290,
          },
          {
            "validTime": "2023-01-22T04:00:00+00:00/PT1H",
            "value": 300,
          },
          {
            "validTime": "2023-01-22T05:00:00+00:00/PT9H",
            "value": 310,
          },
          {
            "validTime": "2023-01-22T14:00:00+00:00/PT1H",
            "value": 300,
          },
          {
            "validTime": "2023-01-22T15:00:00+00:00/PT1H",
            "value": 290,
          },
          {
            "validTime": "2023-01-22T16:00:00+00:00/PT1H",
            "value": 280,
          },
          {
            "validTime": "2023-01-22T17:00:00+00:00/PT1H",
            "value": 250,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT5H",
            "value": 240,
          },
          {
            "validTime": "2023-01-22T23:00:00+00:00/PT1H",
            "value": 230,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT2H",
            "value": 220,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT3H",
            "value": 210,
          },
          {
            "validTime": "2023-01-23T05:00:00+00:00/PT6H",
            "value": 200,
          },
          {
            "validTime": "2023-01-23T11:00:00+00:00/PT2H",
            "value": 210,
          },
          {
            "validTime": "2023-01-23T13:00:00+00:00/PT1H",
            "value": 220,
          },
          {
            "validTime": "2023-01-23T14:00:00+00:00/PT3H",
            "value": 230,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT2H",
            "value": 240,
          },
          {
            "validTime": "2023-01-23T19:00:00+00:00/PT1H",
            "value": 250,
          },
          {
            "validTime": "2023-01-23T20:00:00+00:00/PT2H",
            "value": 260,
          },
          {
            "validTime": "2023-01-23T22:00:00+00:00/PT1H",
            "value": 270,
          },
          {
            "validTime": "2023-01-23T23:00:00+00:00/PT1H",
            "value": 280,
          },
          {
            "validTime": "2023-01-24T00:00:00+00:00/PT5H",
            "value": 290,
          },
          {
            "validTime": "2023-01-24T05:00:00+00:00/PT6H",
            "value": 300,
          },
          {
            "validTime": "2023-01-24T11:00:00+00:00/PT6H",
            "value": 310,
          },
          {
            "validTime": "2023-01-24T17:00:00+00:00/PT3H",
            "value": 300,
          },
          {
            "validTime": "2023-01-24T20:00:00+00:00/PT2H",
            "value": 310,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT1H",
            "value": 300,
          },
          {
            "validTime": "2023-01-24T23:00:00+00:00/PT1H",
            "value": 270,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT1H",
            "value": 260,
          },
          {
            "validTime": "2023-01-25T01:00:00+00:00/PT1H",
            "value": 270,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT1H",
            "value": 280,
          },
          {
            "validTime": "2023-01-25T03:00:00+00:00/PT1H",
            "value": 290,
          },
          {
            "validTime": "2023-01-25T04:00:00+00:00/PT1H",
            "value": 300,
          },
          {
            "validTime": "2023-01-25T05:00:00+00:00/PT3H",
            "value": 310,
          },
          {
            "validTime": "2023-01-25T08:00:00+00:00/PT3H",
            "value": 300,
          },
          {
            "validTime": "2023-01-25T11:00:00+00:00/PT9H",
            "value": 310,
          },
          {
            "validTime": "2023-01-25T20:00:00+00:00/P1D",
            "value": 320,
          },
          {
            "validTime": "2023-01-26T20:00:00+00:00/PT6H",
            "value": 310,
          },
          {
            "validTime": "2023-01-27T02:00:00+00:00/PT3H",
            "value": 300,
          },
          {
            "validTime": "2023-01-27T05:00:00+00:00/PT3H",
            "value": 290,
          },
          {
            "validTime": "2023-01-27T08:00:00+00:00/PT1H",
            "value": 280,
          },
          {
            "validTime": "2023-01-27T09:00:00+00:00/PT2H",
            "value": 270,
          },
          {
            "validTime": "2023-01-27T11:00:00+00:00/PT2H",
            "value": 280,
          },
          {
            "validTime": "2023-01-27T13:00:00+00:00/PT1H",
            "value": 290,
          },
          {
            "validTime": "2023-01-27T14:00:00+00:00/PT3H",
            "value": 300,
          },
          {
            "validTime": "2023-01-27T17:00:00+00:00/PT2H",
            "value": 290,
          },
          {
            "validTime": "2023-01-27T19:00:00+00:00/PT1H",
            "value": 280,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT1H",
            "value": 270,
          },
          {
            "validTime": "2023-01-27T21:00:00+00:00/PT4H",
            "value": 260,
          },
        ],
      },
      "windSpeed": {
        "uom": "wmoUnit:km_h-1",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT1H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": 5.556,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT2H",
            "value": 1.852,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT3H",
            "value": 5.556,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT1H",
            "value": 7.408,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT10H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT7H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT4H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT4H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT2H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT5H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-22T05:00:00+00:00/PT3H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-22T08:00:00+00:00/PT5H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-22T13:00:00+00:00/PT2H",
            "value": 7.408,
          },
          {
            "validTime": "2023-01-22T15:00:00+00:00/PT4H",
            "value": 5.556,
          },
          {
            "validTime": "2023-01-22T19:00:00+00:00/PT2H",
            "value": 7.408,
          },
          {
            "validTime": "2023-01-22T21:00:00+00:00/PT2H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-22T23:00:00+00:00/PT3H",
            "value": 7.408,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT6H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-23T08:00:00+00:00/PT3H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-23T11:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-23T14:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT21H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-24T14:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-24T17:00:00+00:00/PT3H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-24T20:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-24T23:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT6H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-25T08:00:00+00:00/PT6H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-25T14:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-25T17:00:00+00:00/PT3H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-25T20:00:00+00:00/PT3H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-25T23:00:00+00:00/PT3H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-26T02:00:00+00:00/PT3H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-26T05:00:00+00:00/PT6H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-26T11:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-26T14:00:00+00:00/PT3H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-26T17:00:00+00:00/PT2H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-26T19:00:00+00:00/PT1H",
            "value": 20.372,
          },
          {
            "validTime": "2023-01-26T20:00:00+00:00/PT3H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-26T23:00:00+00:00/PT1H",
            "value": 20.372,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT2H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-27T02:00:00+00:00/PT9H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-27T11:00:00+00:00/PT2H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-27T13:00:00+00:00/PT2H",
            "value": 20.372,
          },
          {
            "validTime": "2023-01-27T15:00:00+00:00/PT5H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT3H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-27T23:00:00+00:00/PT2H",
            "value": 22.224,
          },
        ],
      },
      "windGust": {
        "uom": "wmoUnit:km_h-1",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT1H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": 5.556,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT2H",
            "value": 1.852,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT3H",
            "value": 5.556,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT1H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT1H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-20T21:00:00+00:00/PT2H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT6H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-21T08:00:00+00:00/PT5H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT2H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT5H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT9H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-22T08:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-22T11:00:00+00:00/PT2H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-22T13:00:00+00:00/PT2H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-22T15:00:00+00:00/PT4H",
            "value": 9.26,
          },
          {
            "validTime": "2023-01-22T19:00:00+00:00/PT2H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-22T21:00:00+00:00/PT2H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-22T23:00:00+00:00/PT3H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-23T02:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-23T05:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-23T08:00:00+00:00/PT3H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-23T11:00:00+00:00/PT2H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-23T13:00:00+00:00/PT2H",
            "value": 20.372,
          },
          {
            "validTime": "2023-01-23T15:00:00+00:00/PT1H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-23T16:00:00+00:00/PT1H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-23T17:00:00+00:00/PT3H",
            "value": 25.928,
          },
          {
            "validTime": "2023-01-23T20:00:00+00:00/PT3H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-23T23:00:00+00:00/PT3H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-24T02:00:00+00:00/PT3H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-24T05:00:00+00:00/PT3H",
            "value": 25.928,
          },
          {
            "validTime": "2023-01-24T08:00:00+00:00/PT6H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-24T14:00:00+00:00/PT6H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-24T20:00:00+00:00/PT2H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-24T22:00:00+00:00/PT2H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT2H",
            "value": 20.372,
          },
          {
            "validTime": "2023-01-25T02:00:00+00:00/PT5H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-25T07:00:00+00:00/PT2H",
            "value": 20.372,
          },
          {
            "validTime": "2023-01-25T09:00:00+00:00/PT2H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-25T11:00:00+00:00/PT6H",
            "value": 20.372,
          },
          {
            "validTime": "2023-01-25T17:00:00+00:00/PT1H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-25T18:00:00+00:00/PT1H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-25T19:00:00+00:00/PT1H",
            "value": 25.928,
          },
          {
            "validTime": "2023-01-25T20:00:00+00:00/PT1H",
            "value": 27.78,
          },
          {
            "validTime": "2023-01-25T21:00:00+00:00/PT2H",
            "value": 29.632,
          },
          {
            "validTime": "2023-01-25T23:00:00+00:00/PT1H",
            "value": 27.78,
          },
          {
            "validTime": "2023-01-26T00:00:00+00:00/PT2H",
            "value": 25.928,
          },
          {
            "validTime": "2023-01-26T02:00:00+00:00/PT3H",
            "value": 27.78,
          },
          {
            "validTime": "2023-01-26T05:00:00+00:00/PT5H",
            "value": 25.928,
          },
          {
            "validTime": "2023-01-26T10:00:00+00:00/PT1H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-26T11:00:00+00:00/PT3H",
            "value": 22.224,
          },
          {
            "validTime": "2023-01-26T14:00:00+00:00/PT1H",
            "value": 24.076,
          },
          {
            "validTime": "2023-01-26T15:00:00+00:00/PT1H",
            "value": 25.928,
          },
          {
            "validTime": "2023-01-26T16:00:00+00:00/PT2H",
            "value": 27.78,
          },
          {
            "validTime": "2023-01-26T18:00:00+00:00/PT1H",
            "value": 29.632,
          },
          {
            "validTime": "2023-01-26T19:00:00+00:00/PT3H",
            "value": 31.484,
          },
          {
            "validTime": "2023-01-26T22:00:00+00:00/PT1H",
            "value": 29.632,
          },
          {
            "validTime": "2023-01-26T23:00:00+00:00/PT1H",
            "value": 27.78,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT11H",
            "value": 25.928,
          },
          {
            "validTime": "2023-01-27T11:00:00+00:00/PT2H",
            "value": 27.78,
          },
          {
            "validTime": "2023-01-27T13:00:00+00:00/PT2H",
            "value": 29.632,
          },
          {
            "validTime": "2023-01-27T15:00:00+00:00/PT2H",
            "value": 31.484,
          },
          {
            "validTime": "2023-01-27T17:00:00+00:00/PT3H",
            "value": 33.336,
          },
          {
            "validTime": "2023-01-27T20:00:00+00:00/PT3H",
            "value": 35.188,
          },
          {
            "validTime": "2023-01-27T23:00:00+00:00/PT1H",
            "value": 33.336,
          },
          {
            "validTime": "2023-01-28T00:00:00+00:00/PT1H",
            "value": 31.484,
          },
        ],
      },
      "weather": {
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": [
              {
                "coverage": "patchy",
                "weather": "fog",
                "intensity": null,
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
            ],
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": [
              {
                "coverage": "patchy",
                "weather": "freezing_drizzle",
                "intensity": "light",
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
              {
                "coverage": "patchy",
                "weather": "fog",
                "intensity": null,
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
            ],
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT6H",
            "value": [
              {
                "coverage": "areas",
                "weather": "freezing_drizzle",
                "intensity": "light",
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
              {
                "coverage": "patchy",
                "weather": "fog",
                "intensity": null,
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
            ],
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT5H",
            "value": [
              {
                "coverage": "patchy",
                "weather": "freezing_drizzle",
                "intensity": "light",
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
              {
                "coverage": "patchy",
                "weather": "fog",
                "intensity": null,
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
            ],
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT1H",
            "value": [
              {
                "coverage": "areas",
                "weather": "freezing_drizzle",
                "intensity": "light",
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
              {
                "coverage": "patchy",
                "weather": "fog",
                "intensity": null,
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
            ],
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT2H",
            "value": [
              {
                "coverage": "patchy",
                "weather": "freezing_drizzle",
                "intensity": "light",
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
              {
                "coverage": "patchy",
                "weather": "fog",
                "intensity": null,
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
            ],
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/P6DT22H",
            "value": [
              {
                "coverage": null,
                "weather": null,
                "intensity": null,
                "visibility": {
                  "unitCode": "wmoUnit:km",
                  "value": null,
                },
                "attributes": [],
              },
            ],
          },
        ],
      },
      "hazards": {
        "values": [],
      },
      "probabilityOfPrecipitation": {
        "uom": "wmoUnit:percent",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": 2,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": 20,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT6H",
            "value": 30,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT5H",
            "value": 20,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT1H",
            "value": 30,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT2H",
            "value": 20,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT1H",
            "value": 2,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT2H",
            "value": 1,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT2H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T08:00:00+00:00/PT3H",
            "value": 1,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT7H",
            "value": 2,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/P1DT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT6H",
            "value": 1,
          },
          {
            "validTime": "2023-01-23T06:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T12:00:00+00:00/PT6H",
            "value": 4,
          },
          {
            "validTime": "2023-01-23T18:00:00+00:00/PT6H",
            "value": 8,
          },
          {
            "validTime": "2023-01-24T00:00:00+00:00/PT6H",
            "value": 13,
          },
          {
            "validTime": "2023-01-24T06:00:00+00:00/PT6H",
            "value": 5,
          },
          {
            "validTime": "2023-01-24T12:00:00+00:00/PT6H",
            "value": 2,
          },
          {
            "validTime": "2023-01-24T18:00:00+00:00/PT6H",
            "value": 3,
          },
          {
            "validTime": "2023-01-25T00:00:00+00:00/PT6H",
            "value": 2,
          },
          {
            "validTime": "2023-01-25T06:00:00+00:00/PT6H",
            "value": 1,
          },
          {
            "validTime": "2023-01-25T12:00:00+00:00/PT18H",
            "value": 3,
          },
          {
            "validTime": "2023-01-26T06:00:00+00:00/PT6H",
            "value": 4,
          },
          {
            "validTime": "2023-01-26T12:00:00+00:00/PT6H",
            "value": 9,
          },
          {
            "validTime": "2023-01-26T18:00:00+00:00/PT6H",
            "value": 8,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT6H",
            "value": 9,
          },
          {
            "validTime": "2023-01-27T06:00:00+00:00/PT6H",
            "value": 6,
          },
          {
            "validTime": "2023-01-27T12:00:00+00:00/PT6H",
            "value": 8,
          },
          {
            "validTime": "2023-01-27T18:00:00+00:00/PT12H",
            "value": 7,
          },
        ],
      },
      "quantitativePrecipitation": {
        "uom": "wmoUnit:mm",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T06:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T06:00:00+00:00/PT6H",
            "value": 0,
          },
        ],
      },
      "iceAccumulation": {
        "uom": "wmoUnit:mm",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T06:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T06:00:00+00:00/PT6H",
            "value": 0,
          },
        ],
      },
      "snowfallAmount": {
        "uom": "wmoUnit:mm",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T06:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-22T18:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T00:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-23T06:00:00+00:00/PT6H",
            "value": 0,
          },
        ],
      },
      "snowLevel": {
        "values": [],
      },
      "ceilingHeight": {
        "uom": "wmoUnit:m",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT2H",
            "value": 91.44,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": 152.4,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT3H",
            "value": 182.88,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT1H",
            "value": 152.4,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT3H",
            "value": 121.92,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT6H",
            "value": 91.44,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT3H",
            "value": 121.92,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT2H",
            "value": 182.88,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT1H",
            "value": 213.36,
          },
          {
            "validTime": "2023-01-21T08:00:00+00:00/PT1H",
            "value": 304.8,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": 335.28,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT1H",
            "value": 365.76,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT1H",
            "value": 396.24,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT2H",
            "value": 457.2,
          },
          {
            "validTime": "2023-01-21T14:00:00+00:00/PT2H",
            "value": 487.68,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": 518.16,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": 609.6,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": 640.08,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT1H",
            "value": 731.52,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT1H",
            "value": 944.88,
          },
          {
            "validTime": "2023-01-21T21:00:00+00:00/PT1H",
            "value": 1036.32,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": 853.44,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": 487.68,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": 304.8,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": 274.32,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": 243.84,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT1H",
            "value": 213.36,
          },
        ],
      },
      "visibility": {
        "uom": "wmoUnit:m",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": 3427.90272,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT1H",
            "value": 2494.4832,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": 11088.38016,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": 4956.77952,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": 3749.77152,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT1H",
            "value": 1174.82112,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT1H",
            "value": 2365.73568,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT1H",
            "value": 2478.38976,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT1H",
            "value": 2526.67008,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT1H",
            "value": 3089.94048,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT1H",
            "value": 5182.08768,
          },
          {
            "validTime": "2023-01-20T21:00:00+00:00/PT1H",
            "value": 7113.30048,
          },
          {
            "validTime": "2023-01-20T22:00:00+00:00/PT1H",
            "value": 9462.94272,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": 5906.29248,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT1H",
            "value": 5278.64832,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT1H",
            "value": 5085.52704,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT1H",
            "value": 7322.5152,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT1H",
            "value": 9800.90496,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT1H",
            "value": 10669.95072,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT1H",
            "value": 12520.69632,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT1H",
            "value": 12617.25696,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT1H",
            "value": 12842.56512,
          },
          {
            "validTime": "2023-01-21T08:00:00+00:00/PT1H",
            "value": 13759.8912,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": 14290.97472,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT1H",
            "value": 14113.94688,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT1H",
            "value": 14146.13376,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT1H",
            "value": 14645.0304,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT1H",
            "value": 13051.77984,
          },
          {
            "validTime": "2023-01-21T14:00:00+00:00/PT1H",
            "value": 13083.96672,
          },
          {
            "validTime": "2023-01-21T15:00:00+00:00/PT1H",
            "value": 13936.91904,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": 12472.416,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": 11007.91296,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": 9543.40992,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT1H",
            "value": 11490.71616,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT1H",
            "value": 13438.0224,
          },
          {
            "validTime": "2023-01-21T21:00:00+00:00/PT1H",
            "value": 15385.32864,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": 15272.67456,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": 15160.02048,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": 15047.3664,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": 10428.54912,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": 5793.6384,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT1H",
            "value": 1174.82112,
          },
        ],
      },
      "transportWindSpeed": {
        "uom": "wmoUnit:km_h-1",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT5H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT3H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-20T21:00:00+00:00/PT1H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-20T22:00:00+00:00/PT2H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT2H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT3H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-21T08:00:00+00:00/PT1H",
            "value": 18.52,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT4H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT1H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-21T14:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT2H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT3H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT2H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT3H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT2H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-22T05:00:00+00:00/PT1H",
            "value": 16.668,
          },
          {
            "validTime": "2023-01-22T06:00:00+00:00/PT2H",
            "value": 14.816,
          },
          {
            "validTime": "2023-01-22T08:00:00+00:00/PT2H",
            "value": 12.964,
          },
          {
            "validTime": "2023-01-22T10:00:00+00:00/PT1H",
            "value": 11.112,
          },
          {
            "validTime": "2023-01-22T11:00:00+00:00/PT2H",
            "value": 9.26,
          },
        ],
      },
      "transportWindDirection": {
        "uom": "wmoUnit:degree_(angle)",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT3H",
            "value": 310,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT4H",
            "value": 300,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT1H",
            "value": 290,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT4H",
            "value": 280,
          },
          {
            "validTime": "2023-01-20T22:00:00+00:00/PT3H",
            "value": 270,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT2H",
            "value": 280,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT1H",
            "value": 270,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT5H",
            "value": 280,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT4H",
            "value": 270,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT1H",
            "value": 260,
          },
          {
            "validTime": "2023-01-21T14:00:00+00:00/PT4H",
            "value": 250,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT2H",
            "value": 260,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT4H",
            "value": 270,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT2H",
            "value": 280,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT2H",
            "value": 290,
          },
          {
            "validTime": "2023-01-22T04:00:00+00:00/PT9H",
            "value": 300,
          },
        ],
      },
      "mixingHeight": {
        "uom": "wmoUnit:m",
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT1H",
            "value": 132.8928,
          },
          {
            "validTime": "2023-01-20T11:00:00+00:00/PT1H",
            "value": 125.8824,
          },
          {
            "validTime": "2023-01-20T12:00:00+00:00/PT1H",
            "value": 117.9576,
          },
          {
            "validTime": "2023-01-20T13:00:00+00:00/PT1H",
            "value": 118.872,
          },
          {
            "validTime": "2023-01-20T14:00:00+00:00/PT1H",
            "value": 120.0912,
          },
          {
            "validTime": "2023-01-20T15:00:00+00:00/PT1H",
            "value": 111.8616,
          },
          {
            "validTime": "2023-01-20T16:00:00+00:00/PT1H",
            "value": 146.304,
          },
          {
            "validTime": "2023-01-20T17:00:00+00:00/PT1H",
            "value": 271.8816,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT1H",
            "value": 363.9312,
          },
          {
            "validTime": "2023-01-20T19:00:00+00:00/PT1H",
            "value": 455.9808,
          },
          {
            "validTime": "2023-01-20T20:00:00+00:00/PT1H",
            "value": 444.3984,
          },
          {
            "validTime": "2023-01-20T21:00:00+00:00/PT1H",
            "value": 439.5216,
          },
          {
            "validTime": "2023-01-20T22:00:00+00:00/PT1H",
            "value": 334.0608,
          },
          {
            "validTime": "2023-01-20T23:00:00+00:00/PT1H",
            "value": 210.9216,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT1H",
            "value": 190.5,
          },
          {
            "validTime": "2023-01-21T01:00:00+00:00/PT1H",
            "value": 239.8776,
          },
          {
            "validTime": "2023-01-21T02:00:00+00:00/PT1H",
            "value": 239.268,
          },
          {
            "validTime": "2023-01-21T03:00:00+00:00/PT1H",
            "value": 239.8776,
          },
          {
            "validTime": "2023-01-21T04:00:00+00:00/PT1H",
            "value": 216.408,
          },
          {
            "validTime": "2023-01-21T05:00:00+00:00/PT1H",
            "value": 224.3328,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT1H",
            "value": 196.2912,
          },
          {
            "validTime": "2023-01-21T07:00:00+00:00/PT1H",
            "value": 216.1032,
          },
          {
            "validTime": "2023-01-21T08:00:00+00:00/PT1H",
            "value": 213.0552,
          },
          {
            "validTime": "2023-01-21T09:00:00+00:00/PT1H",
            "value": 227.9904,
          },
          {
            "validTime": "2023-01-21T10:00:00+00:00/PT1H",
            "value": 259.08,
          },
          {
            "validTime": "2023-01-21T11:00:00+00:00/PT1H",
            "value": 252.984,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT1H",
            "value": 245.6688,
          },
          {
            "validTime": "2023-01-21T13:00:00+00:00/PT1H",
            "value": 241.4016,
          },
          {
            "validTime": "2023-01-21T14:00:00+00:00/PT1H",
            "value": 191.1096,
          },
          {
            "validTime": "2023-01-21T15:00:00+00:00/PT1H",
            "value": 205.1304,
          },
          {
            "validTime": "2023-01-21T16:00:00+00:00/PT1H",
            "value": 213.6648,
          },
          {
            "validTime": "2023-01-21T17:00:00+00:00/PT1H",
            "value": 235.0008,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT1H",
            "value": 264.8712,
          },
          {
            "validTime": "2023-01-21T19:00:00+00:00/PT1H",
            "value": 285.5976,
          },
          {
            "validTime": "2023-01-21T20:00:00+00:00/PT1H",
            "value": 288.3408,
          },
          {
            "validTime": "2023-01-21T21:00:00+00:00/PT1H",
            "value": 267.9192,
          },
          {
            "validTime": "2023-01-21T22:00:00+00:00/PT1H",
            "value": 222.8088,
          },
          {
            "validTime": "2023-01-21T23:00:00+00:00/PT1H",
            "value": 169.4688,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT1H",
            "value": 129.2352,
          },
          {
            "validTime": "2023-01-22T01:00:00+00:00/PT1H",
            "value": 117.6528,
          },
          {
            "validTime": "2023-01-22T02:00:00+00:00/PT1H",
            "value": 128.016,
          },
          {
            "validTime": "2023-01-22T03:00:00+00:00/PT1H",
            "value": 149.0472,
          },
          {
            "validTime": "2023-01-22T04:00:00+00:00/PT1H",
            "value": 170.9928,
          },
          {
            "validTime": "2023-01-22T05:00:00+00:00/PT1H",
            "value": 191.1096,
          },
          {
            "validTime": "2023-01-22T06:00:00+00:00/PT1H",
            "value": 209.0928,
          },
          {
            "validTime": "2023-01-22T07:00:00+00:00/PT1H",
            "value": 224.6376,
          },
          {
            "validTime": "2023-01-22T08:00:00+00:00/PT1H",
            "value": 236.22,
          },
          {
            "validTime": "2023-01-22T09:00:00+00:00/PT1H",
            "value": 243.5352,
          },
          {
            "validTime": "2023-01-22T10:00:00+00:00/PT1H",
            "value": 244.1448,
          },
          {
            "validTime": "2023-01-22T11:00:00+00:00/PT1H",
            "value": 238.0488,
          },
          {
            "validTime": "2023-01-22T12:00:00+00:00/PT1H",
            "value": 222.8088,
          },
        ],
      },
      "hainesIndex": {
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/PT8H",
            "value": 3,
          },
          {
            "validTime": "2023-01-20T18:00:00+00:00/PT6H",
            "value": 2,
          },
          {
            "validTime": "2023-01-21T00:00:00+00:00/PT6H",
            "value": 3,
          },
          {
            "validTime": "2023-01-21T06:00:00+00:00/PT6H",
            "value": 4,
          },
          {
            "validTime": "2023-01-21T12:00:00+00:00/PT6H",
            "value": 5,
          },
          {
            "validTime": "2023-01-21T18:00:00+00:00/PT6H",
            "value": 4,
          },
          {
            "validTime": "2023-01-22T00:00:00+00:00/PT6H",
            "value": 3,
          },
          {
            "validTime": "2023-01-22T06:00:00+00:00/PT7H",
            "value": 4,
          },
        ],
      },
      "lightningActivityLevel": {
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/P2DT3H",
            "value": 1,
          },
        ],
      },
      "twentyFootWindSpeed": {
        "values": [],
      },
      "twentyFootWindDirection": {
        "values": [],
      },
      "waveHeight": {
        "values": [],
      },
      "wavePeriod": {
        "values": [],
      },
      "waveDirection": {
        "values": [],
      },
      "primarySwellHeight": {
        "values": [],
      },
      "primarySwellDirection": {
        "values": [],
      },
      "secondarySwellHeight": {
        "values": [],
      },
      "secondarySwellDirection": {
        "values": [],
      },
      "wavePeriod2": {
        "values": [],
      },
      "windWaveHeight": {
        "values": [],
      },
      "dispersionIndex": {
        "values": [],
      },
      "pressure": {
        "values": [],
      },
      "probabilityOfTropicalStormWinds": {
        "values": [],
      },
      "probabilityOfHurricaneWinds": {
        "values": [],
      },
      "potentialOf15mphWinds": {
        "values": [],
      },
      "potentialOf25mphWinds": {
        "values": [],
      },
      "potentialOf35mphWinds": {
        "values": [],
      },
      "potentialOf45mphWinds": {
        "values": [],
      },
      "potentialOf20mphWindGusts": {
        "values": [],
      },
      "potentialOf30mphWindGusts": {
        "values": [],
      },
      "potentialOf40mphWindGusts": {
        "values": [],
      },
      "potentialOf50mphWindGusts": {
        "values": [],
      },
      "potentialOf60mphWindGusts": {
        "values": [],
      },
      "grasslandFireDangerIndex": {
        "values": [],
      },
      "probabilityOfThunder": {
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/P6DT14H",
            "value": 0,
          },
          {
            "validTime": "2023-01-27T00:00:00+00:00/PT12H",
            "value": 1,
          },
          {
            "validTime": "2023-01-27T12:00:00+00:00/PT6H",
            "value": 0,
          },
          {
            "validTime": "2023-01-27T18:00:00+00:00/PT6H",
            "value": 1,
          },
        ],
      },
      "davisStabilityIndex": {
        "values": [],
      },
      "atmosphericDispersionIndex": {
        "values": [],
      },
      "lowVisibilityOccurrenceRiskIndex": {
        "values": [],
      },
      "stability": {
        "values": [],
      },
      "redFlagThreatIndex": {
        "values": [
          {
            "validTime": "2023-01-20T10:00:00+00:00/P3DT2H",
            "value": 0,
          },
        ],
      },
    },
  },
  "status": 200,
  "statusText": "",
  "headers": {
    "cache-control": "public, max-age=3588, s-maxage=3600",
    "content-length": "8302",
    "content-type": "application/geo+json",
    "expires": "Fri, 20 Jan 2023 18:53:05 GMT",
    "x-correlation-id": "cf96db00",
    "x-request-id": "8c2af75d-ba8d-4e95-a1bb-23a62bf028b8",
    "x-server-id": "vm-bldr-nids-apiapp6.ncep.noaa.gov",
  },
  "config": {
    "transitional": {
      "silentJSONParsing": true,
      "forcedJSONParsing": true,
      "clarifyTimeoutError": false,
    },
    "transformRequest": [null],
    "transformResponse": [null],
    "timeout": 0,
    "xsrfCookieName": "XSRF-TOKEN",
    "xsrfHeaderName": "X-XSRF-TOKEN",
    "maxContentLength": -1,
    "maxBodyLength": -1,
    "env": {
      "FormData": null,
    },
    "headers": {
      "Accept": "application/json, text/plain, */*",
    },
    "method": "get",
    "url": "https://api.weather.gov/gridpoints/FSD/99,65",
  },
  "request": {},
};

export default AdvanceForcast;
