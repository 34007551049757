let data = [
  {
    "ID": "14735417-f776-4e4e-bf9f-11bc124a42ed",
    "Index": 0,
    "Name": "Los Angeles",
    "Lat": 33.973951,
    "Lon": -118.248405,
    "Zip": 90001,
    "State": "CA",
  },
];

export default data;
