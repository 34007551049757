import React from "react";
import * as Helper from "../Helper/HelperFunctions";
import { TbArrowUpCircle } from "react-icons/tb";

export default function OtherDataSection({ forecast, loaded, basicForecast, fahrenheit }) {
  let feelsLike;
  let windDirection;
  let windSpeed;
  if (loaded) feelsLike = Helper.CtoF(forecast.data.properties.apparentTemperature.values[Helper.advanceIndex(forecast.data.properties.apparentTemperature.values, loaded)].value, fahrenheit);
  if (loaded) windDirection = forecast.data.properties.windDirection.values[Helper.advanceIndex(forecast.data.properties.windDirection.values, loaded)].value;
  if (loaded) windSpeed = Helper.KtoM(forecast.data.properties.windSpeed.values[Helper.advanceIndex(forecast.data.properties.windSpeed.values, loaded)].value, loaded);

  function CardDirection() {
    var val = Math.floor(windDirection / 22.5 + 0.5);
    var arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    return arr[val % 16];
  }

  let windDirectionText = CardDirection(windDirection);

  const style = {
    "transform": "rotate(" + windDirection + "deg)",
  };

  return (
    <div>
      <div className="flex flex-row gap-2 justify-center">
        <span>Feels Like</span>
        <span>{feelsLike}&#176;</span>
      </div>
      <div className="flex flex-row w-full items-center justify-center gap-2">
        {/* <div className="">
        <span>Feels Like </span>
        <span>{feelsLike}</span>
      </div>
      <div className="">asdf</div> */}
        <span className="text-2xl">{windSpeed}mph</span>
        <span>
          <TbArrowUpCircle style={style} className="text-5xl" />
        </span>
        <span className="text-2xl">{windDirectionText}</span>
      </div>
    </div>
  );
}
