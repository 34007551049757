import React from "react";
import * as Helper from "../Helper/HelperFunctions";
import Stormy from "./WeatherIcons/Stormy";
import Snowy from "./WeatherIcons/Snowy";
import Rainy from "./WeatherIcons/Rainy";
import Hazy from "./WeatherIcons/Hazy";
import Cloudy from "./WeatherIcons/Cloudy";
import Sunny from "./WeatherIcons/Sunny";

export default function Day({ date, MinMax, basicForecast, loaded, dayMax, dayMin }) {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + date);

  let weatherPriority = ["storm", "snow", "rain", "fog", "cloudy", "sunny"];
  let weatherIcons = [<Stormy />, <Snowy />, <Rainy />, <Hazy />, <Cloudy />, <Sunny />];
  let icon = <Sunny />;
  let IconIndex = Helper.basicIndexWithSetDate(basicForecast.data.properties.periods, loaded, currentDate);
  // icon = basicForecast.data.properties.periods[IconIndex].icon;

  if (loaded) {
    for (let i = 0; i < weatherPriority.length; i++) {
      if (basicForecast.data.properties.periods[IconIndex].detailedForecast.toLowerCase().includes(weatherPriority[i])) {
        icon = weatherIcons[i];
        break;
      }
    }
  }

  let DayOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat", "Today"];

  //   progressArray.map((item) => {
  //     if (item > MinMax.Min && item < MinMax.Max) return (item = 1);
  //     else return (item = 0);
  //   });
  //   console.log(progressArray);

  let progressArray;
  if (loaded) {
    let progressArrayLength = Math.ceil(dayMax) - Math.ceil(dayMin) + 1;
    progressArray = Array(progressArrayLength).fill(1);
    for (let i = 0; i < progressArray.length; i++) {
      progressArray[i] = Math.ceil(dayMin) + i;
    }
    // console.log(progressArray);
  }

  if (loaded) {
    for (let i = 0; i < progressArray.length; i++) {
      if (Math.ceil(MinMax.Min) - 1 < progressArray[i] && Math.ceil(MinMax.Max) + 1 > progressArray[i]) {
        progressArray[i] = 1;
      } else {
        progressArray[i] = 0;
      }
    }
  }
  if (icon === weatherIcons[5]) {
    icon = <div>{icon}</div>;
  } else {
    icon = <div className="WeatherIconShiftY">{icon}</div>;
  }

  //   console.log(progressArray);

  return (
    <div className="flex flex-row gap-4 justify-end items-center">
      <span>{DayOfWeek[date]}</span>
      <span>{Math.ceil(MinMax.Min)}&#176;</span>
      <span>
        <div id="bar" className="border border-white w-16 h-2 rounded-full">
          <div id="progressBar" className="w-full flex flex-row justify-evenly items-center h-full">
            {progressArray.map((item, index) => {
              if (item === 0) return <div className="w-full h-full" key={index}></div>;
              else return <div className="h-full w-full bg-green-500" key={index}></div>;
            })}
          </div>
        </div>
        <span></span>
      </span>
      <span>{Math.ceil(MinMax.Max)}&#176;</span>
      <div className="rounded-3xl  w-1 h-8">
        {icon}
        {/* <img className="w-8 h-8" src={icon} alt="icon" /> */}
      </div>
    </div>
  );
}
