import React from "react";

export default function Dots({ dotIndex }) {
  let data = JSON.parse(localStorage.getItem("LocationList")) || [];

  return (
    <div className="fixed bottom-0 w-full h-6 flex flex-row justify-center items-center z-20 pb-6 gap-2">
      {data.map((items, index) => {
        if (items.Index === dotIndex) {
          return <div className="w-4 h-4 bg-white rounded-full" key={index}></div>;
        } else {
          return <div className="w-4 h-4 bg-gray-600 rounded-full" key={index}></div>;
        }
      })}
    </div>
  );
}
