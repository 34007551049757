import React from "react";
import * as Helper from "../Helper/HelperFunctions";

export default function UpdatedSection({ forecast, loaded }) {
  //   const [updated, setUpdated] = useState("Loading");

  let Time = Helper.LocalTime(forecast.data.properties.temperature.values, loaded);
  let Date = Helper.LocalDate(forecast.data.properties.temperature.values, loaded);

  return (
    <div className="flex flex-col justify-center items-center CardContent">
      <div className="text-lg">{Date}</div>
      <div className="text-xs">last updated {Time}</div>
    </div>
  );
}
