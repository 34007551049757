import React, { useState, useEffect } from "react";
import axios from "axios";
import baseForecast from "../Schema/Forcast";
import AdvanceForcast from "../Schema/AdvanceForcast";
import TempSection from "./TempSection";
import UpdatedSection from "./UpdatedSection";
import DaysSection from "./DaysSection";
import OtherDataSection from "./OtherDataSection";
import Background from "./Background";
import GridData from "./GridData";
import HourlySection from "./HourlySection";
// import StickyHeader from "./StickyHeader";

export default function Card({ areas, index, setAreas, fahrenheit, setFahrenheit, areasList }) {
  const [forecast, setForecast] = useState(AdvanceForcast);
  const [basicForecast, setBasicForecast] = useState(baseForecast);
  const [loaded, setLoaded] = useState(false);

  let WeatherBaseURL = "https://api.weather.gov/points/" + areas.Lat + "," + areas.Lon;

  useEffect(() => {
    axios
      .get(WeatherBaseURL)
      .then((response) => {
        axios.get(response.data.properties.forecast).then((response) => {
          setBasicForecast(response);
        });
        axios.get(response.data.properties.forecastGridData).then((response) => {
          setForecast(response);
          setLoaded(true);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [WeatherBaseURL]);

  function getWeather() {
    console.log(forecast, "ADVANCED");
    console.log(basicForecast, "BASIC");
  }

  function deleteCard(ID) {
    let newAreaList = [];
    areasList.map((items) => {
      if (items.ID !== ID) newAreaList.push(items);
      return null;
    });
    setAreas(newAreaList);
    localStorage.setItem("LocationList", JSON.stringify(newAreaList));
  }
  let loadingPage;
  if (!loaded) {
    loadingPage = (
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
        <div className="bg-white w-80 h-12 flex flex-col justify-center items-center mb-4 mt-4">Loading...</div>
      </div>
    );
  } else {
    loadingPage = <div className="hidden"></div>;
  }

  return (
    <>
      {loadingPage}
      <div className="Card w-96 flex flex-col justify-start items-center transition-all opacity-100 z-10 mx-6 text-white" style={{ float: "left" }}>
        <Background basicForecast={basicForecast} loaded={loaded} />
        <div className="sticky top-0 bg-transparent w-full h-auto flex flex-col justify-center items-center mt-6" onClick={getWeather}>
          <TempSection forecast={forecast} basicForecast={basicForecast} loaded={loaded} fahrenheit={fahrenheit} />
        </div>

        {/* Card Scroll */}
        <div className="fixed scrollWidth top-32 h-4/5 overflow-scroll center flex flex-col items-center opacity-100">
          <div className="CardBack w-80 h-12 flex flex-col justify-center items-center mb-4 mt-4">
            <div className="CardContent">{areas.Name}</div>
          </div>
          <div className="CardBack w-80 h-12 flex flex-col justify-center items-center mb-4">
            <UpdatedSection forecast={forecast} loaded={loaded} />
          </div>
          <div id={"ID" + areas.Index} className="CardBack w-80 flex flex-col justify-center items-center mb-4">
            <div className="sticky top-0 w-full CardBackTitle bg-opacity-90 flex flex-row justify-start z-20">
              <span className="opacity-100">
                Hourly Weather <span className="text-xs opacity-50"></span>
              </span>
            </div>
            {/* <StickyHeader ID={"ID" + areas.Index} loaded={loaded} /> */}
            <div className="flex flex-col items-center">
              <HourlySection forecast={forecast} basicForecast={basicForecast} loaded={loaded} fahrenheit={fahrenheit} />
            </div>
          </div>
          <div id={"ID" + areas.Index} className="CardBack w-80 flex flex-col justify-center items-center mb-4 pb-4">
            <div className="sticky top-0 w-full CardBackTitle bg-opacity-90 flex flex-row justify-start">
              <span className="opacity-100">Daily Weather</span>
            </div>
            {/* <StickyHeader ID={"ID" + areas.Index} loaded={loaded} /> */}
            <div className="flex flex-col items-center WeatherIconShiftX">
              <DaysSection forecast={forecast} basicForecast={basicForecast} loaded={loaded} fahrenheit={fahrenheit} />
            </div>
          </div>
          <div className="CardBack w-80 h-36 flex flex-col justify-center items-center mb-4">
            <OtherDataSection forecast={forecast} basicForecast={basicForecast} loaded={loaded} fahrenheit={fahrenheit} />
          </div>
          <div className="bg-transparent w-80 h-auto mb-4">
            <GridData forecast={forecast} loaded={loaded} fahrenheit={fahrenheit} />
          </div>
          <div className="CardBack w-80 h-12 flex flex-col justify-center items-center mb-4" onClick={() => deleteCard(areas.ID)}>
            delete
          </div>
          <div className="w-80 h-12 flex flex-col justify-center items-center mb-8">
            <span className="text-xs">
              data provided by{" "}
              <a href="https://www.weather.gov/" target={"_blank"} rel="noreferrer">
                National Weather Service
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
